import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function QRouter() {
    const { id } = useParams();
    let navigate = useNavigate();
    const firestore = getFirestore();

    useEffect(() => {
        console.log(id);
        if (id != null) {
            routeTimelineWithParamsHandler(id);
        }
    }, []);

    async function routeTimelineWithParamsHandler(target) {
        const docRef = doc(firestore, "QRoutes/" + target);
        console.log(docRef);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                let obiRoute = docSnap.data()["route"];
                navigate("/timeline/" + obiRoute);
            } else {
                navigate("/QRouter");
            }
        });
    }

    return (
        <>
            <>I failed to guide you, stranger.</>
        </>
    );
}

export default QRouter;
