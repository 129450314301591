import React, { useRef, useState, useEffect } from "react";
import { Form, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../store/AuthContext";
import { useNavigate } from "react-router-dom";
import classes from "./authentication.module.css";
import { ref } from "firebase/storage";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
} from "@chakra-ui/react";

import { updateProfile } from "firebase/auth";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Signup = (props) => {
    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const fullNameRef = useRef();
    const { signup, setUserProfile, userProfile, currentUser } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const firestore = getFirestore();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        console.log("Creating Account! let's navigate")
        console.log(currentUser)
        console.log(userProfile)
        console.log(loading)

        if (currentUser && userProfile!="") {
            setLoading(false);
            navigate("/profile");
        }
    }, [currentUser, userProfile]);



    async function handleSubmit(e) {
        // e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match");
        }

        try {
            setError("");
            setLoading(true);
            console.log(" am I signing up? ");
            await signup(
                emailRef.current.value,
                passwordRef.current.value
            ).then(async (userCredential) => {
                //Creates a User Profile Doc inside of FireStore and navigates to profile page
                const user = userCredential.user;
                updateProfile(user, { displayName: name });
                const docRef = doc(firestore, "users/" + user.uid);
                console.log("this is a string ref" + new String(fullNameRef));
                let DOCDATA = {
                    Editor_Obi_IDS: [],
                    Name: name,
                    Email: user.email,
                    Owned_Obi_IDS: [],
                };
                console.log("not navigating yet");
                PleaseSetThisDoc(docRef, DOCDATA).then(() => {
                    setUserProfile(DOCDATA);
                });
            });
        } catch(e) {
            alert("An alert has occured: " +JSON.stringify(e))
            setError("Failed to create an account");
        }
        setLoading(false);
    }

    async function PleaseSetThisDoc(docRef, DOCDATA) {
        console.log("Am I making it here?");
        await setDoc(docRef, DOCDATA);
    }

    const nameChangeHandler = (event) => {
        event.preventDefault();
        setName(event.target.value);
        console.log(name);
    };
    //console.log(JSON.stringify(user)+" :This is current User")
    //console.log(typeof signup+"--This is the type, and also should be function " + typeof handleSubmit)

    return (
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"} textAlign={"center"}>
                        Sign up :)
                    </Heading>
                </Stack>
                <Box
                    rounded={"lg"}
                    bg={useColorModeValue("white", "gray.700")}
                    boxShadow={"lg"}
                    p={8}
                >
                    <Stack spacing={4}>
                        <HStack>
                            <Box>
                                <FormControl id="firstName" isRequired>
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        onChange={nameChangeHandler}
                                        type="text"
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl id="lastName">
                                    <FormLabel>Last Name</FormLabel>
                                    <Input type="text" />
                                </FormControl>
                            </Box>
                        </HStack>
                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input ref={emailRef} type="email" />
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    ref={passwordRef}
                                    type={showPassword ? "text" : "password"}
                                />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowPassword(
                                                (showPassword) => !showPassword
                                            )
                                        }
                                    >
                                        {showPassword ? (
                                            <ViewIcon />
                                        ) : (
                                            <ViewOffIcon />
                                        )}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Confirm Password</FormLabel>
                            <InputGroup>
                                <Input
                                    ref={passwordConfirmRef}
                                    type={showPassword ? "text" : "password"}
                                />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowPassword(
                                                (showPassword) => !showPassword
                                            )
                                        }
                                    >
                                        {showPassword ? (
                                            <ViewIcon />
                                        ) : (
                                            <ViewOffIcon />
                                        )}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Stack spacing={10} pt={2}>
                            <Button
                                onClick={() => handleSubmit()}
                                loadingText="Submitting"
                                size="lg"
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500",
                                }}
                            >
                                Sign up
                            </Button>
                        </Stack>
                        <Stack pt={6}>
                            <Text
                                align={"center"}
                                onClick={() => {
                                    props.loggingIn(true);
                                }}
                            >
                                Already a user?{" "}
                                <Link color={"blue.400"}>Login</Link>
                            </Text>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );

    //   return (
    //     <>
    //       <Card style={{borderColor:'#4d647f'}}>
    //         <Card.Body>
    //         <h1 className="text-center mb-4"> Prop Stack</h1>
    //           <h4 className="text-center mb-4">Sign Up</h4>
    //           {error && <Alert variant="danger">{error}</Alert>}
    //           <Form onSubmit={handleSubmit}>
    //           <Form.Group id="fullName">
    //               <Form.Label>Full Name</Form.Label>
    //               <Form.Control type="fullName" onChange={nameChangeHandler} required style={{borderColor:'#4d647f'}}/>
    //             </Form.Group>
    //             <Form.Group id="email">
    //               <Form.Label>Email</Form.Label>
    //               <Form.Control type="email" ref={emailRef} required style={{borderColor:'#4d647f'}}/>
    //             </Form.Group>
    //             <Form.Group id="password">
    //               <Form.Label>Password</Form.Label>
    //               <Form.Control type="password" ref={passwordRef} required style={{borderColor:'#4d647f'}} />
    //             </Form.Group>
    //             <Form.Group id="password-confirm">
    //               <Form.Label>Password Confirmation</Form.Label>
    //               <Form.Control type="password" ref={passwordConfirmRef} required style={{borderColor:'#4d647f'}} />
    //             </Form.Group>
    //             <Button disabled={loading} className={classes.paddedButton} style={{backgroundColor:'#4d647f', borderColor:'#4d647f'}} type="submit">
    //               Sign Up
    //             </Button>
    //           </Form>
    //         </Card.Body>
    //       </Card>
    //       <div  className="w-100 text-center mt-2">
    //         Already have an account? Log In
    //       </div>
    //       <button onClick={ () => {props.loggingIn(true)}}>
    //         Log In
    //       </button>
    //     </>
    //   )
};
export default Signup;
