import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { GoogleAuthProvider, getAuth, getRedirectResult } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { Alert } from "react-bootstrap";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [userObituary, setUserObituary] = useState();
    const [userProfile, setUserProfile] = useState("");
    const [loggingIn, setLoggingIn] = useState(true);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const firestore = getFirestore();

    useEffect(() => {

        console.log(
            userProfile + " this better be User Profile " + typeof userProfile + userProfile.length
        );
        console.log(userProfile=="")
        console.log("This better be false -- loggingIn " + loggingIn);
        if (typeof userProfile == "object") {
            window.localStorage.setItem(
                "userProfile",
                JSON.stringify(userProfile)
            );
            
            if (
                loggingIn &&
                window.localStorage.getItem("loggingIn") != "false"
            ) {
                
                window.localStorage.setItem("loggingIn", "false");
                setLoggingIn(false);
                //navigate("/Profile");
                //This is the one I just commented out...
            }
        } else {
            const Data = JSON.parse(window.localStorage.getItem("userProfile"));
            if (Data != null) {
                console.log(" this is userProfile"+ Data)
                setUserProfile(Data);
            }
        }
        

        console.log(
            userProfile + " this is now User Profile " + typeof userProfile
        );
    }, [userProfile]);

    const provider = new GoogleAuthProvider();

    function signup(email, password) {
        console.log("correct function");
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function setTimeline(Timeline) {
        setUserObituary(Timeline);
    }

    //Can clean up file later by moving this specifically to Login Page
    const signInWithGoogle = () => {
        auth.signInWithPopup(provider)
            .then((result) => {
                let user = result.user;
                const docRef = doc(firestore, "users/" + user.uid);
                getDoc(docRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        console.log(
                            docSnap.data(),
                            " I better see some data here for my document snap... "
                        );
                        console.log("setting userProfile!" + docSnap.data())
                        setUserProfile(docSnap.data());
                        //window.localStorage.setItem(
                        //    "userProfile",
                        //    JSON.stringify(docSnap.data())
                        //);
                        //console.log("Document data:", docSnap.data());
                    } else {
                        console.log(
                            "First Time Signing in with Google, document must be created"
                        );
                        let DOCDATA = {
                            Editor_Obi_IDS: [],
                            Name: user.displayName,
                            Email: user.email,
                            Owned_Obi_IDS: [],
                        };

                        setDoc(docRef, DOCDATA).then(() => {
                            setUserProfile(DOCDATA);
                            //window.localStorage.setItem(
                            //    "userProfile",
                            //    JSON.stringify(DOCDATA)
                            //);
                        });
                    }
                });
            })
            .catch((error) => {
                Alert(error)
                console.log(error+ " this is google popup error");
            });
        //navigate("/Profile");
    };


    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, [userProfile]);

    const value = {
        currentUser,
        userObituary,
        userProfile,
        setLoggingIn,
        setUserObituary,
        setUserProfile,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        signInWithGoogle,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
