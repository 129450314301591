import React from "react";
import {
    Heading,
    Stack,
    Text,
    Button,
    useColorModeValue,
} from "@chakra-ui/react";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";

const HomeButtons = (props) => {
    const { currentUser } = useAuth();
    let navigate = useNavigate();

    function buyClick() {
        if (currentUser) {
            navigate("/buy");
        } else {
            navigate("/login");
        }
    }
    return (
        <>
            <Stack
                textAlign={"center"}
                align={"center"}
                spacing={{ base: 8, md: 50 }}
                py={{ base: 20, md: 20 }}
            >
                <Heading
                    // bg={"red.100"}
                    fontWeight={600}
                    fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
                    lineHeight={"110%"}
                    color={"white"}
                >
                    <TypeAnimation
                        sequence={[
                            "Remembering loved ones made easy", // Types 'One'
                            10000, // Waits 1s
                        ]}
                        wrapper="div"
                        cursor={true}
                        repeat={Infinity}
                        style={{ color: "white" }}
                    />
                </Heading>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: "3xl", sm: "2xl", md: "4xl" }}
                    lineHeight={"110%"}
                >
                    <Text
                        color="white"
                        fontSize="2xl"
                        px={3}
                        align={"center"}
                        backgroundColor="rgba(72,156,228, 0.6)"
                        rounded={"3xl"}
                    >
                        Create an online memorial with family and friends{" "}
                        <br></br>
                        Receive a QR code that attaches onto any surface such as
                        a tombstone, memorial, picture frame, and more
                    </Text>
                </Heading>
                <Stack textAlign={"center"} align={"center"}>
                    <Button
                        color="white"
                        margin="1em"
                        size="lg"
                        rounded={"full"}
                        px={6}
                        colorScheme={"orange"}
                        bg={"blue.400"}
                        _hover={{ bg: "blue.500" }}
                        onClick={(e) => {
                            {
                                if (currentUser != null) {
                                    navigate("/buypre");
                                } else {
                                    navigate("/login");
                                }
                            }
                        }}
                    >
                        Buy A Timeline With Our Money-Back Guarantee
                    </Button>
                </Stack>
            </Stack>
        </>
    );
    // }
};

export default HomeButtons;
