import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../store/AuthContext";
import {
    Avatar,
    Button,
    Heading,
    Center,
    Box,
    Stack,
    Text,
    Textarea,
    Image,
    Flex,
    Spacer,
    HStack,
    VStack,
    Right,
} from "@chakra-ui/react";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    getDocs,
    collection,
    updateDoc,
    deleteDoc,
} from "firebase/firestore";
import Modal from "../UI/Modal";
import { DeleteIcon, Icon } from "@chakra-ui/icons";


// let monthMap= new Map();
// monthMap.set("01", "January");
// monthMap.set("02", "February");
// monthMap.set("03", "March");
// monthMap.set("04", "April");
// monthMap.set("05", "May");
// monthMap.set("06", "June");
// monthMap.set("07", "July");
// monthMap.set("08", "August");
// monthMap.set("09", "September");
// monthMap.set("10", "October");
// monthMap.set("11", "November");
// monthMap.set("12", "December");




function Memory(props) {
    const [memoryID, setMemoryID] = useState(null);
    const firestore = getFirestore();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [hideMem, setHideMem] = useState(false);
    const [hidePhoto, setHidePhoto] = useState(false);
    const [showfullImage, setShowFullImage] = useState(false);
    const [showfullText, setShowFullText] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [photoSrc, setPhotoSrc] = useState("https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg?20200418092106")
    let showElipsis = props.memory.memory.Body.length > 280;
    const code = props.timeLineCode.trim();
    const docID = props.memory.docId.trim();
    const monthMap = new Map([["01", "January"],["02", "February"],["03", "March"],["04", "April"],["05", "May"],["06", "June"],["07", "July"],["08", "August"],["09", "September"],["10", "October"],["11", "November"],["12", "December"]])

    useEffect(() => {

        //console.log(props.timeLineCode.trim()+ " this is code")
        
        if(props.memory.memory
            .PicExists){
                setPhotoSrc(props.memory.memory
                    .PicExists)
        }
        if (currentUser!=null){
            if(props.author.trim() ==
            currentUser.uid.trim()){setShowDeleteButton(true)}
        }

        if (props.memory.id != null) {
            //console.log("WE DO HAVE an ID?" + props.memory.docId)
            setMemoryID(props.memory.docId);
        }

        /* This code can be used to hide photo component if no photo exists, but then it looks ugly...
    if (props.memory.memory.PicExists == false) {
      setHidePhoto(true)
    }
    */
    }, []);

    async function deleteMemory() {
        console.log(props.timeLineCode.trim()+ " and code "+ code)
        //const code = props.timeLineCode.trim();
        //const docID = props.memory.docId.trim();
        
        await deleteDoc(
            doc(firestore, "/obituaries/" + code + "/memories/" + docID)
        );
        console.log("Delete went through..." + props.timeLineCode.trim());
        setHideMem(true);
        setShowDeleteModal(false);
    }

    const { currentUser } = useAuth();
    return (
        (
            <>
                {showfullImage && (
                    <Modal
                        closeModal={() => {
                            setShowFullImage(false);
                        }}
                    >
                        
                        <img src={props.memory.memory.PicExists}></img>
                    </Modal>
                )}
                {showfullText && (
                    <Modal
                        closeModal={() => {
                            setShowFullText(false);
                        }}
                    >
                        
                        <HStack>
                    
                            <Avatar
                                onClick={() => {
                                    setShowFullImage(true);
                                    //console.log(
                                    //    hidePhoto + " this is hidePhoto"
                                    //);
                                }}
                                size="2xl"
                                src={props.memory.memory.PicExists}
                            />
                            <VStack>                                
                                <Text fontWeight={600}>                                   
                                    Memory Author:{" "}
                                    {props.memory.memory.AuthorName}
                                </Text>
                                <Text fontWeight={400}>
                                    {" "}
                                    Date: {props.memory.memory.Date}
                                </Text>
                            </VStack>
                        </HStack>
                        <Textarea top="20px" height="300px" width="500px">
                            {props.memory.memory.Body}
                        </Textarea>
                    </Modal>
                )}
                {!hideMem && (
                    <div>
                        {showDeleteModal && (
                            <Modal
                                closeModal={() => {
                                    setShowDeleteModal(false);
                                }}
                            >
                                <Heading size="2x-1">
                                    Are you sure you want to delete this memory?
                                    Once a memory is deleted, it can not be
                                    recovered.
                                </Heading>
                                <Button
                                    onClick={() => {
                                        setShowDeleteModal(false);
                                    }}
                                >
                                    Return to Timeline
                                </Button>
                                
                                <Button onClick={()=>{
                                    console.log(props.timeLineCode.trim())
                                    deleteMemory()}}>
                                    Delete Memory
                                </Button>
                            </Modal>
                        )}

                        {/* This code below is what is actually used to display an individual memory... */}
                        <Flex py={6} m={6} alignItems={'center'} direction={'center'}>
                            
                        <Center  >
                            <Box

                                maxW={"445px"}
                                w={"full"}
                                // bg={useColorModeValue('white', 'gray.900')}
                                boxShadow={"2xl"}
                                rounded={"md"}
                                p={6}
                                overflow={"hidden"}
                            >
                                
               
                                <Flex>
                                    <Box>
                                
                                        {!hidePhoto && (
                                            <>
                                            <Image
                                            max-height="50vh"
                                            max-width="50vw"
                                            flex-shrink="0"
                                            
                                            borderRadius="25px"
                                            src={
                                               photoSrc
                                            }
                                            cursor="pointer"
                                            onClick={() => {
                                                setShowFullImage(true);
                                            }}
                                            />
                                            <Spacer/>
                                            
                                            </>
                                        )}
                                        
                                    </Box>


                                </Flex>

                                <Center>
                                    <VStack
                                        mt={6}
                                        direction={"row"}
                                        spacing={2}
                                        align={"left"}

                                    >

                                           
                                                <Text fontWeight={600}>
                                                    
                                                    Memory Author:{" "}
                                                    {
                                                        props.memory.memory
                                                            .AuthorName
                                                    }
                                                </Text>
                                                <Text fontWeight={400}>
                                                    {" "}
                                                    Date:{" "}
                                                    {monthMap.get(props.memory.memory.Date.substring(5,7)) +" "+props.memory.memory.Date.substring(8,)+ ", "+props.memory.memory.Date.substring(0,4)}
                                                </Text>
      



                                        <Text
                                            paddingBottom="5px"
                                            onClick={() => {
                                                setShowFullText(true);
                                            }}
                                            fontWeight={600}
                                            alignItems={"center"}
                                            cursor="pointer"
                                        >
                                            {props.memory.memory.Body.substring(
                                                0,
                                                280
                                            )}
                                            {showElipsis && "..."}
                                            
                                        </Text>
                                      
                                    </VStack>
                                   
                                </Center>
                                {showDeleteButton && 
                                 <DeleteIcon 
                                 position="relative"
                                 left="90%"
                                     
                                    
                                     cursor="pointer"  onClick={() => {
                                             setShowDeleteModal(true);
                                         }} w={6} h={6} />  
                                }
                                 
                            </Box>
                            
                        </Center>
                        
                        </Flex>
                    </div>
                )}
            </>
        )
    );
}

export default Memory;
