import React, { useRef, useState, useEffect } from "react";
import { Form, Card, Alert } from "react-bootstrap";
import { useAuth } from "../store/AuthContext";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    useColorMode,
    Box,
    SimpleGrid,
    GridItem,
    chakra,
    Stack,
    Input,
    Text,
    Select,
    HStack,
} from "@chakra-ui/react";
import Modal from "../components/UI/Modal";
import { stripePaymentHandler } from "../store/payments";
import { Navigate, useNavigate } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";

import { fetchFromAPI } from "../store/payments";
//import { stripe } from "../store/payments";
import { useStripe } from "@stripe/react-stripe-js";
import firebase from "firebase/compat/app";

function Buy() {
    const { currentUser, userProfile } = useAuth(); // State for curr user.
    const [loading, setLoading] = useState(false); // Not sure why we need this.
    const firestore = getFirestore(); // Access to firestore.
    const [isAlive, setIsAlive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [deathDate, setDeathDate] = useState(null);
    const { colorMode, toggleColorMode } = useColorMode();
    const [birthDay, setBirthDay] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [deathDay, setDeathDay] = useState("");
    const [deathMonth, setDeathMonth] = useState("");
    const [deathYear, setDeathYear] = useState("");
    let navigate = useNavigate();

    //
    // Refs to the fields in the form.
    const nameRef = useRef();
    const birthRef = useRef("");
    const deathRef = useRef("");

    const address1Ref = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const emailRef = useRef();
    const purchaseFirstNameRef = useRef();
    const purchaseLastNameRef = useRef();
    const functions = getFunctions();

    const NUMS = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];
    const YEARS = [
        1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910, 1911,
        1912, 1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922, 1923,
        1924, 1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935,
        1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947,
        1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959,
        1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971,
        1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983,
        1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995,
        1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007,
        2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
        2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031,
        2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043,
        2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055,
        2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067,
        2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079,
        2080, 2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091,
        2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099,
    ];
    const mappedDays = NUMS.map((val) => {
        return <option value={val}>{val}</option>;
    });
    const mappedMonths = [
        <option value="01">January</option>,
        <option value="02">February</option>,
        <option value="03">March</option>,
        <option value="04">April</option>,
        <option value="05">May</option>,
        <option value="06">June</option>,
        <option value="07">July</option>,
        <option value="08">August</option>,
        <option value="09">September</option>,
        <option value="10">October</option>,
        <option value="11">November</option>,
        <option value="12">December</option>,
    ];
    const mappedYears = YEARS.map((val) => {
        return <option value={val}>{val}</option>;
    });

    //const buyThing = httpsCallable(functions, 'expressApi')
    const StripeCheckout = httpsCallable(functions, "stripeCheckout");

    const stripe = useStripe();
    //export const buyThing = functions.httpsCallable('expressApi')

    useEffect(() => {
        if (localStorage.getItem("XFM143") != null) {
            localStorage.removeItem("XFM143");
        }
        console.log(JSON.stringify(window.history) + " this is what I get...");
    }, []);

    //
    // Function that creates the obituaries.
    async function createObi(e) {
        console.log("Am I running?");

        if (!nameRef.current.value) {
            alert("A Name must be entered to complete the purchase.");
            return;
        }

        if (
            !address1Ref.current.value ||
            !cityRef.current.value ||
            !stateRef.current.value ||
            !zipRef.current.value ||
            !emailRef.current.value ||
            !purchaseFirstNameRef.current.value ||
            !purchaseLastNameRef.current.value
        ) {
            alert("All inputs should be filled to complete the purchase.");
            return;
        }

        let DeathString = "";
        if (isAlive != true) {
            //let newDate = formYear + "-" + formMonth + "-" + formDay
            DeathString = deathYear + "-" + deathMonth + "-" + deathDay;
            //deathRef.current.value;
        }

        const obiID = currentUser.uid +String(userProfile.Owned_Obi_IDS.length)
        const obituaryRef =
            "obituaries/" + obiID

        console.log("this is new obiRef" + obituaryRef);
        const obituaryData = {
            Name: nameRef.current.value,
            Birthday: birthYear + "-" + birthMonth + "-" + birthDay,
            Death: deathYear + "-" + deathMonth + "-" + deathDay,
            Bio: "Edit Your Bio...",
            Owner: currentUser.uid,
            Editors: [],
            NumofMems: 0,
            Profile: "",
            Background: "",
        };
        console.log("This is OBItuary Ref "+ currentUser.uid +String(userProfile.Owned_Obi_IDS.length) )
        console.log("This is OBIID"+ currentUser.uid + String(userProfile.Owned_Obi_IDS.length) )
        const purchaseData = {
            UID: currentUser.uid,
            OBIID: obiID,
            AddressLine1: address1Ref.current.value,
            ShipmentFirstname: purchaseFirstNameRef.current.value,
            ShipmentLastname: purchaseLastNameRef.current.value,
            ObiName: nameRef.current.value,
            City: cityRef.current.value,
            Email: emailRef.current.value,
            State: stateRef.current.value,
            ZipCode: zipRef.current.value,
            "!SHIPPED": false,
            "!QRoute": "null",
        };

        let OwnedTimelines;

        if (userProfile.Owned_Obi_IDS.length == 0) {
            const timeLineID = new String(currentUser.uid) + "0";
            OwnedTimelines = new Array(timeLineID);
        } else {
            const timeLineID = new String(currentUser.uid).concat(
                new String(userProfile.Owned_Obi_IDS.length)
            );
            OwnedTimelines = userProfile.Owned_Obi_IDS;
            OwnedTimelines.push(timeLineID);
        }
        //
        //const newOwnedTimelines = OwnedTimelines

        const purchaseRef =
            "Purchases/" +
            currentUser.uid +
            String(userProfile.Owned_Obi_IDS.length - 1);

       
        const AllData = {
            purchaseData: purchaseData,
            purchaseRef: purchaseRef,
            newOwnedTimelines: OwnedTimelines,
            obituaryData: obituaryData,
            obituaryRef: obituaryRef,
        };
        window.localStorage.setItem("XFM143", JSON.stringify(AllData));

        
        const session = await StripeCheckout({ data: "foo" });
        console.log(
            JSON.stringify(session) + " I made it here" + session.data.url
        );

        window.location.replace(session.data.url);
            
    }

    return (
        <>
            {colorMode === "light" ? (
                <div
                    style={{
                        backgroundColor: "white",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#1A202C",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            )}
            <div style={{ position: "relative", bottom: "10em" }}>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* Testing starts here */}
                <Box m={3}>
                    <SimpleGrid
                        display={{
                            base: "initial",
                            md: "grid",
                        }}
                        columns={{
                            md: 3,
                        }}
                        spacing={{
                            md: 1,
                        }}
                    >
                        <GridItem>
                            <Box ml={30}>
                                <Box mt={[10, 0]}>
                                    <SimpleGrid
                                        display={{
                                            base: "initial",
                                            md: "grid",
                                        }}
                                        columns={{
                                            md: 1,
                                        }}
                                        spacing={{
                                            md: 6,
                                        }}
                                    >
                                        <GridItem
                                            colSpan={{
                                                md: 1,
                                            }}
                                        ></GridItem>

                                        <GridItem
                                            mt={[5, null, 0]}
                                            colSpan={{
                                                md: 2,
                                            }}
                                        >
                                            <chakra.form
                                                shadow="base"
                                                rounded={[null, "md"]}
                                                overflow={{
                                                    sm: "hidden",
                                                }}
                                            >
                                                <Stack
                                                    px={4}
                                                    py={5}
                                                    p={[null, 6]}
                                                    bg="white"
                                                    _dark={{
                                                        bg: "#141517",
                                                    }}
                                                    spacing={6}
                                                >
                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={6}
                                                    >
                                                        <Box px={[4, 0]}>
                                                            <Heading
                                                                fontSize="lg"
                                                                fontWeight="medium"
                                                                lineHeight="6"
                                                            >
                                                                Our Guarantee:
                                                            </Heading>
                                                            <br></br>
                                                            <Text
                                                                mt={1}
                                                                fontSize="sm"
                                                                color="gray.600"
                                                                _dark={{
                                                                    color: "gray.400",
                                                                }}
                                                            >
                                                                Our affordable,
                                                                one-time fee
                                                                allows you to
                                                                remember your
                                                                loved ones. If
                                                                you're not
                                                                satisfied with
                                                                your purchase,
                                                                enjoy our 1
                                                                month money back
                                                                guarantee.
                                                            </Text>
                                                        </Box>
                                                        <br></br>
                                                        <br></br>
                                                        <FormLabel
                                                            htmlFor="first_name"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Full name
                                                        </FormLabel>
                                                        <Input
                                                            ref={nameRef}
                                                            type="text"
                                                            name="first_name"
                                                            id="first_name"
                                                            autoComplete="given-name"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={6}
                                                    >
                                                        <FormLabel
                                                            htmlFor="birth_date"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Birth Date:
                                                        </FormLabel>
                                                        <HStack>
                                                            <Select
                                                                show={10}
                                                                width={1 / 3}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBirthDay(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Day"
                                                            >
                                                                {mappedDays}
                                                            </Select>
                                                            <Select
                                                                width={1 / 3}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBirthMonth(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Month"
                                                            >
                                                                {mappedMonths}
                                                            </Select>
                                                            <Select
                                                                width={1 / 3}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBirthYear(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Year"
                                                            >
                                                                {mappedYears}
                                                            </Select>
                                                        </HStack>
                                                        {/* <Input
                                                                type="date"
                                                                ref={birthRef}
                                                                required
                                                                style={{
                                                                    borderColor:
                                                                        "#4d647f",
                                                                }}
                                                            /> */}
                                                    </FormControl>

                                                    <>
                                                        <FormControl
                                                            as={GridItem}
                                                            colSpan={6}
                                                        >
                                                            <FormLabel
                                                                htmlFor="pass_date"
                                                                fontSize="sm"
                                                                fontWeight="md"
                                                                color="gray.700"
                                                                _dark={{
                                                                    color: "gray.50",
                                                                }}
                                                            >
                                                                Passing Date:
                                                            </FormLabel>
                                                            <HStack>
                                                                <Select
                                                                    show={10}
                                                                    width={
                                                                        1 / 3
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setDeathDay(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                        console.log(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Day"
                                                                >
                                                                    {mappedDays}
                                                                </Select>
                                                                <Select
                                                                    width={
                                                                        1 / 3
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setDeathMonth(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                        console.log(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Month"
                                                                >
                                                                    {
                                                                        mappedMonths
                                                                    }
                                                                </Select>
                                                                <Select
                                                                    width={
                                                                        1 / 3
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setDeathYear(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                        console.log(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Year"
                                                                >
                                                                    {
                                                                        mappedYears
                                                                    }
                                                                </Select>
                                                            </HStack>
                                                            {/* <Input
                                                                        type="date"
                                                                        ref={
                                                                            deathRef
                                                                        }
                                                                        required
                                                                        style={{
                                                                            borderColor:
                                                                                "#4d647f",
                                                                        }}
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setDeathDate(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    /> */}
                                                        </FormControl>
                                                    </>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={6}
                                                    ></FormControl>
                                                </Stack>
                                            </chakra.form>
                                        </GridItem>
                                    </SimpleGrid>
                                </Box>
                            </Box>
                        </GridItem>

                        <GridItem
                            mt={[5, null, 0]}
                            colSpan={{
                                md: 2,
                            }}
                        >
                            <Box mt={[10, 0]}>
                                <SimpleGrid
                                    display={{
                                        base: "initial",
                                        md: "grid",
                                    }}
                                    columns={{
                                        md: 3,
                                    }}
                                    spacing={{
                                        md: 6,
                                    }}
                                >
                                    <GridItem
                                        colSpan={{
                                            md: 1,
                                        }}
                                    >
                                        <Box px={[4, 0]}>
                                            {/* <Heading
                                                fontSize="lg"
                                                fontWeight="medium"
                                                lineHeight="6"
                                            >
                                                Residence Information
                                            </Heading>
                                            <Text
                                                mt={1}
                                                fontSize="sm"
                                                color="gray.600"
                                                _dark={{
                                                    color: "gray.400",
                                                }}
                                            >
                                                Please provide us with your
                                                address to receive your personalized QR code!
                                                A QR code will make adding memories a much easier
                                                and expedited process!
                                            </Text> */}
                                        </Box>
                                    </GridItem>
                                    <GridItem
                                        mt={[5, null, 0]}
                                        colSpan={{
                                            md: 2,
                                        }}
                                    >
                                        <chakra.form
                                            // method="POST"
                                            shadow="base"
                                            rounded={[null, "md"]}
                                            overflow={{
                                                sm: "hidden",
                                            }}
                                        >
                                            <Stack
                                                px={4}
                                                py={5}
                                                p={[null, 6]}
                                                bg="white"
                                                _dark={{
                                                    bg: "#141517",
                                                }}
                                                spacing={6}
                                            >
                                                <Heading
                                                    fontSize="lg"
                                                    fontWeight="medium"
                                                    lineHeight="6"
                                                >
                                                    Residence Information
                                                </Heading>
                                                <Text
                                                    mt={1}
                                                    fontSize="sm"
                                                    color="gray.600"
                                                    _dark={{
                                                        color: "gray.400",
                                                    }}
                                                >
                                                    Please provide us with your
                                                    address to receive your
                                                    personalized QR code! A QR
                                                    code will make adding
                                                    memories a much easier and
                                                    expedited process!
                                                </Text>

                                                <SimpleGrid
                                                    columns={6}
                                                    spacing={6}
                                                >
                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[6, 3]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="first_name"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            First name
                                                        </FormLabel>
                                                        <Input
                                                            ref={
                                                                purchaseFirstNameRef
                                                            }
                                                            type="text"
                                                            name="first_name"
                                                            id="first_name"
                                                            autoComplete="given-name"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[6, 3]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="last_name"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Last name
                                                        </FormLabel>
                                                        <Input
                                                            ref={
                                                                purchaseLastNameRef
                                                            }
                                                            type="text"
                                                            name="last_name"
                                                            id="last_name"
                                                            autoComplete="family-name"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[6, 4]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="email_address"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Email address
                                                        </FormLabel>
                                                        <Input
                                                            ref={emailRef}
                                                            type="email"
                                                            name="email_address"
                                                            id="email_address"
                                                            autoComplete="email"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[6, 3]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="country"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Country / Region
                                                        </FormLabel>
                                                        <Select
                                                            id="country"
                                                            name="country"
                                                            autoComplete="country"
                                                            placeholder="Select option"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        >
                                                            <option>
                                                                United States
                                                            </option>
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={6}
                                                    >
                                                        <FormLabel
                                                            htmlFor="street_address"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            Street address
                                                        </FormLabel>
                                                        <Input
                                                            ref={address1Ref}
                                                            type="text"
                                                            name="street_address"
                                                            id="street_address"
                                                            autoComplete="street-address"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[
                                                            6,
                                                            6,
                                                            null,
                                                            2,
                                                        ]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="city"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            City
                                                        </FormLabel>
                                                        <Input
                                                            ref={cityRef}
                                                            type="text"
                                                            name="city"
                                                            id="city"
                                                            autoComplete="city"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[
                                                            6,
                                                            3,
                                                            null,
                                                            2,
                                                        ]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="state"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            State / Province
                                                        </FormLabel>
                                                        <Input
                                                            ref={stateRef}
                                                            type="text"
                                                            name="state"
                                                            id="state"
                                                            autoComplete="state"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        as={GridItem}
                                                        colSpan={[
                                                            6,
                                                            3,
                                                            null,
                                                            2,
                                                        ]}
                                                    >
                                                        <FormLabel
                                                            htmlFor="postal_code"
                                                            fontSize="sm"
                                                            fontWeight="md"
                                                            color="gray.700"
                                                            _dark={{
                                                                color: "gray.50",
                                                            }}
                                                        >
                                                            ZIP / Postal
                                                        </FormLabel>
                                                        <Input
                                                            ref={zipRef}
                                                            type="number"
                                                            name="postal_code"
                                                            id="postal_code"
                                                            autoComplete="postal-code"
                                                            mt={1}
                                                            focusBorderColor="brand.400"
                                                            shadow="sm"
                                                            size="sm"
                                                            w="full"
                                                            rounded="md"
                                                        />
                                                    </FormControl>
                                                </SimpleGrid>
                                            </Stack>
                                            <Box
                                                px={{
                                                    base: 4,
                                                    sm: 6,
                                                }}
                                                py={3}
                                                bg="gray.50"
                                                _dark={{
                                                    bg: "#121212",
                                                }}
                                                textAlign="right"
                                            >
                                                <Button
                                                    onClick={() => {
                                                        // setShowModal(true);
                                                        createObi();
                                                    }}
                                                    disabled={loading}
                                                    style={{ padding: "0.5em" }}
                                                    bg={"blue.400"}
                                                    color={"white"}
                                                    _hover={{
                                                        bg: "blue.500",
                                                    }}
                                                >
                                                    Buy {isAlive && "Timeline"}
                                                    {!isAlive &&
                                                        "Obituary"}{" "}
                                                    {/* This button doesn't have any checks on the data... Use with caution. */}
                                                </Button>
                                            </Box>
                                        </chakra.form>
                                    </GridItem>
                                </SimpleGrid>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                </Box>
            </div>
            <Text textAlign={"center"} p={3}>
                © 2022 Memento Memories. All rights reserved. Created using
                Chakra Templates.{" "}
            </Text>
        </>
    );
}

export default Buy;
