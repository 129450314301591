import React from "react";
import "./Home.css";
import {
    Flex,
    Heading,
    Stack,
    Text,
    Icon,
    Box,
    useColorModeValue,
    chakra,
    SimpleGrid,
    Image,
    Button,
    useBreakpointValue,
    Center,
} from "@chakra-ui/react";
import memoriesImage from "../assets/memories.jpg";
import memoriesImage2 from "../assets/memories2.jpg";
import QRCODE from "../assets/QRCODE2.jpg";
import GIF from "../assets/GIF.gif";
import example from "../assets/ExamplePic.jpg";
import HomeButtons from "../components/UI/HomeButtons.js";
import { Parallax, Background } from "react-parallax";
import { TypeAnimation } from "react-type-animation";
import ImageSlider from "../../src/components/UI/ImageSlider";
import { SlideData } from "./SlideData";

const Specs = () => {
    const Feature = (props) => {
        return (
            <Box>
                <Icon
                    boxSize={12}
                    _light={{
                        color: "brand.700",
                    }}
                    mb={4}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    {props.icon}
                </Icon>
                <chakra.h3
                    mb={3}
                    fontSize="lg"
                    lineHeight="shorter"
                    fontWeight="bold"
                    _light={{
                        color: "gray.900",
                    }}
                >
                    {props.title}
                </chakra.h3>
                <chakra.p
                    lineHeight="tall"
                    color="gray.600"
                    _dark={{
                        color: "gray.400",
                    }}
                >
                    {props.children}
                </chakra.p>
            </Box>
        );
    };

    return (
        <Flex
            bg="#edf3f8"
            _dark={{
                bg: "#3e3e3e",
            }}
            p={20}
            w="auto"
            justifyContent="center"
            alignItems="center"
        >
            <SimpleGrid
                columns={{
                    base: 1,
                    md: 4,
                    lg: 4,
                }}
                spacing={20}
                px={{
                    base: 4,
                    lg: 16,
                    xl: 20,
                }}
                py={10}
                mx="auto"
                bg="white"
                _dark={{
                    bg: "gray.800",
                }}
                shadow="xl"
            >
                <Heading mb={4} fontSize={{ base: "2xl", md: "4xl" }}>
                    3 easy steps to making your memorial
                </Heading>
                <Feature
                    title="Register"
                    icon={
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    }
                >
                    Create an account with Memento Memories
                </Feature>

                <Feature
                    title="Create online memorial"
                    icon={
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    }
                >
                    Start a timeline of their life and add memories. A
                    personalised QR code will be shipped to you.
                </Feature>

                <Feature
                    title="Invite Others"
                    icon={
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    }
                >
                    Allow others to contribute to the memorial.
                </Feature>
            </SimpleGrid>
        </Flex>
    );
};

function Home() {
    // const colors = new Array();

    // colors[0] = new Image();
    // colors[0].src = '../assets/memories.jpg';

    // colors[1] = new Image();
    // colors[1].src = '../assets/memories2.jpg';

    // colors[2] = new Image();
    // colors[2].src = '../assets/memories3.jpg';
    // const colors = [];
    // colors[0].src = '../assets/memories.jpg';
    // colors[1].src = '../assets/memories.jpg';
    // colors[2].src = '../assets/memories.jpg';

    // const delay = 2500;

    // const [index, setIndex] = React.useState(0);
    // const timeoutRef = React.useRef(null);

    // function resetTimeout() {
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //     }
    // }

    // React.useEffect(() => {
    //     resetTimeout();
    //     timeoutRef.current = setTimeout(
    //         () =>
    //             setIndex((prevIndex) =>
    //                 prevIndex === colors.length - 1 ? 0 : prevIndex + 1
    //             ),
    //         delay
    //     );

    //     return () => {
    //         resetTimeout();
    //     };
    // }, [index]);

    return (
        <div>
            <Stack>
                <Parallax bgImage={memoriesImage} strength={500}>
                    <div style={{ height: 500 }}>
                        <div>
                            <HomeButtons />
                        </div>
                    </div>
                </Parallax>
            </Stack>
            <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
                <Flex p={8} flex={1} align={"center"} justify={"center"}>
                    <Stack spacing={6} w={"full"} maxW={"lg"}>
                        <Heading
                            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                        >
                            <Text
                                as={"span"}
                                position={"relative"}
                                _after={{
                                    content: "''",
                                    width: "full",
                                    height: useBreakpointValue({
                                        base: "20%",
                                        md: "30%",
                                    }),
                                    position: "absolute",
                                    bottom: 1,
                                    left: 0,
                                    bg: "blue.400",
                                    zIndex: -1,
                                }}
                            >
                                Timelines
                            </Text>
                            <br />{" "}
                            {/* <Text color={'blue.400'} as={'span'}>
                                Design Projects
                            </Text>{' '} */}
                        </Heading>
                        <Text
                            fontSize={{ base: "md", lg: "lg" }}
                            color={"gray.500"}
                        >
                            Family and friends can upload images, videos and
                            text. Try scrolling through our interactive timeline
                            below!
                        </Text>
                        <Stack
                        // direction={{ base: "column", md: "row" }}
                        // alignItems={"left"}
                        >
                            {/* <Button
                                rounded={'full'}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}>
                                Example Timeline
                            </Button> */}

                            {/* <Image src={GIF} /> */}
                            <Box>
                                <iframe
                                    style={{
                                        width: "100%",
                                        height: "600px",
                                    }}
                                    src="https://mementomemories.com/timeline/6CguOk8oIuOtUWYCUkKXjeklq4A316"
                                    title="Iframe"
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Box w="100%" p={6}>
                        <Stack spacing={6} w={"full"} maxW={"lg"}>
                            <Heading
                                marginTop={7}
                                fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                            >
                                <Text
                                    as={"span"}
                                    position={"relative"}
                                    _after={{
                                        content: "''",
                                        width: "full",
                                        height: useBreakpointValue({
                                            base: "20%",
                                            md: "30%",
                                        }),
                                        position: "absolute",
                                        bottom: 1,
                                        left: 0,
                                        bg: "blue.400",
                                        zIndex: -1,
                                    }}
                                >
                                    Shareable QR Codes
                                </Text>
                                <br />{" "}
                                {/* <Text color={'blue.400'} as={'span'}>
                                Design Projects
                            </Text>{' '} */}
                            </Heading>
                            <Text
                                fontSize={{ base: "md", lg: "lg" }}
                                color={"gray.500"}
                            >
                                Put your QR Code wherever to share a lifetime of
                                memories
                            </Text>
                        </Stack>
                        <Stack
                            marginTop={7}
                            direction={{ base: "column", md: "row" }}
                            alignItems={"left"}
                        >
                            {/* <Button
                                rounded={'full'}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}>
                                Example Timeline
                            </Button> */}

                            <ImageSlider slides={SlideData} />
                        </Stack>
                    </Box>
                    {/* <Image
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={example}
                    // border={"solid"}
                    // borderWidth={"6px"}
                    // borderRadius={"25px"}
                    />  */}
                </Flex>
            </Stack>
            <Parallax bgImage={memoriesImage2} strength={500}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                    }}
                    alignItems="center"
                    maxW="sm"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                >
                    <Heading
                        alignItems="center"
                        fontWeight={600}
                        fontSize={{ base: "2xl", sm: "2xl", md: "4xl" }}
                        lineHeight={"110%"}
                        color={"white"}
                    >
                        <TypeAnimation
                            sequence={[
                                "Try It!", // Types 'One'
                                10000, // Waits 1s
                            ]}
                            wrapper="div"
                            cursor={true}
                            repeat={Infinity}
                            style={{ color: "white" }}
                        />
                    </Heading>
                    {/* <br></br> */}
                    <Text
                        fontWeight={600}
                        fontSize={{ base: "2xl", sm: "2xl", md: "3xl" }}
                        // lineHeight={"110%"}
                        color={"white"}
                        // width={"50%"}
                        // bg={useColorModeValue("blue.400", "gray.800")}
                        // backgroundColor="rgba(52, 52, 52, 0.8)"
                        borderRadius="lg"
                        padding="1em"
                        alignItems={"center"}
                    >
                        Scan QR Code:
                    </Text>
                    <a href="https://mementomemories.com/timeline/6CguOk8oIuOtUWYCUkKXjeklq4A316">
                        <Image
                            boxSize="200px"
                            src={QRCODE}
                            alt="Quick Responde Code"
                        />
                    </a>
                </div>

                <div style={{ height: "100000" }}>
                    <div></div>
                </div>
            </Parallax>
            <Specs></Specs>
            <Text textAlign={"center"} p={2} m={2}>
                © 2022 Memento Memories. All rights reserved. Created using
                Chakra Templates.{" "}
            </Text>
        </div>
    );
}

export default Home;
