import React from "react";
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../store/AuthContext";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    getDocs,
    collection,
    updateDoc,

} from "firebase/firestore";
import { Form } from "react-bootstrap";
import { EditIcon } from "@chakra-ui/icons";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { Avatar } from "@chakra-ui/react";
import {
    Input,
    Button,
    ButtonGroup,
    Heading,
    Select,
    VStack,
    HStack,
    Text,
    Flex,
    Stack,
    useColorModeValue,
    Center,
    Image,
    Box,
    useColorMode
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Modal from "../components/UI/Modal";
import Memory from "../components/Timelines/Memory";
import MemoryWrapper from "../components/Timelines/MemoryWrapper";

function FindTimeline() {
    const monthMap = new Map([["01", "January"],["02", "February"],["03", "March"],["04", "April"],["05", "May"],["06", "June"],["07", "July"],["08", "August"],["09", "September"],["10", "October"],["11", "November"],["12", "December"]])

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { id } = useParams();
    const [timeLineCode, setTimeLineCode] = useState("");
    const [FriendTimeline, setFriendTimeline] = useState(null);
    const [memories, setMemories] = useState([]);
    const firestore = getFirestore();
    const [memoryBody, setMemoryBody] = useState("");
    //const [memoryDate, setMemoryDate] = useState(`${year}-${month}-${day}`);
    const [showEditModal, setShowEditModal] = useState(false);
    const [memoryPhotos, setMemoryPhotos] = useState(false);
    const [loading, setLoading] = useState(false);
    const { currentUser, userProfile, setUserProfile } = useAuth();
    const [memPhotoURLs, setMemPhotoURLs] = useState(null);
    const [showAddMem, setShowAddMem] = useState(false);
    const [hideSearch, setHideSearch] = useState(false);
    const nameRef = useRef();
    const birthRef = useRef();
    const deathRef = useRef();
    const bioRef = useRef();
    const memPhotoRef = useRef("/");
    const backgroundRef = useRef();
    const profilePhotoRef = useRef();
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [backgroundPhoto, setBackgroundPhoto] = useState(null);
    const [testing, setTesting] = useState([]);

    const [ formYear, setFormYear]=useState("");
    const [ formMonth, setFormMonth]=useState("");
    const [ formDay, setFormDay]=useState("");
    const [ShowEditProfileIcon, setShowEditProfileIcon] = useState(false);

    const { colorMode, toggleColorMode } = useColorMode();
    let globalString = "testing global string";
    const NUMS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    const YEARS = [1900,
        1901, 1902, 1903, 1904, 1905,
        1906, 1907, 1908, 1909, 1910,
        1911, 1912, 1913, 1914, 1915,
        1916, 1917,
        1918,
        1919,
        1920,
        1921,
        1922,
        1923,
        1924,
        1925,
        1926,
        1927,
        1928,
        1929,
        1930,
        1931,
        1932,
        1933,
        1934,
        1935,
        1936,
        1937,
        1938,
        1939,
        1940,
        1941,
        1942,
        1943,
        1944,
        1945,
        1946,
        1947,
        1948,
        1949,
        1950,
        1951,
        1952,
        1953,
        1954,
        1955,
        1956,
        1957,
        1958,
        1959,
        1960,
        1961,
        1962,
        1963,
        1964,
        1965,
        1966,
        1967,
        1968,
        1969,
        1970,
        1971,
        1972,
        1973,
        1974,
        1975,
        1976,
        1977,
        1978,
        1979,
        1980,
        1981,
        1982,
        1983,
        1984,
        1985,
        1986,
        1987,
        1988,
        1989,
        1990,
        1991,
        1992,
        1993,
        1994,
        1995,
        1996,
        1997,
        1998,
        1999,
        2000,
        2001,
        2002,
        2003,
        2004,
        2005,
        2006,
        2007,
        2008,
        2009,
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030,
        2031,
        2032,
        2033,
        2034,
        2035,
        2036,
        2037,
        2038,
        2039,
        2040,
        2041,
        2042,
        2043,
        2044,
        2045,
        2046,
        2047,
        2048,
        2049,
        2050,
        2051,
        2052,
        2053,
        2054,
        2055,
        2056,
        2057,
        2058,
        2059,
        2060,
        2061,
        2062,
        2063,
        2064,
        2065,
        2066,
        2067,
        2068,
        2069,
        2070,
        2071,
        2072,
        2073,
        2074,
        2075,
        2076,
        2077,
        2078,
        2079,
        2080,
        2081,
        2082,
        2083,
        2084,
        2085,
        2086,
        2087,
        2088,
        2089,
        2090,
        2091,
        2092,
        2093,
        2094,
        2095,
        2096,
        2097,
        2098,
        2099,]
    const mappedDays = NUMS.map((val) => { return (<option value={val}>{val}</option>) })
    const mappedMonths = [<option value="01">January</option>, <option value="02">February</option>, <option value="03">March</option>, <option value="04">April</option>, <option value="05">May</option>, <option value="06">June</option>, <option value="07">July</option>, <option value="08">August</option>, <option value="09">September</option>, <option value="10">October</option>, <option value="11">November</option>, <option value="12">December</option>,]
    const mappedYears = YEARS.map((val) => { return (<option value={val}>{val}</option>) })
    //console.log("This is memoryDate saved in state... "+ memoryDate)

    useEffect(() => {
        console.log("Use effect ran, and here is memory Date... ")
        if (id != null) {
            if (timeLineCode != id.trim()) {
                setTimeLineCode(id.trim())
            }
            pullTimelineWithParamsHandler(id);
            setHideSearch(true);
        }

        console.log("just testing initializing ref with value " + memPhotoRef.current)
    }, [timeLineCode]);

    useEffect(() => {
        if (FriendTimeline != null) showingAddMemHelper();
    }, [FriendTimeline]);

    useEffect(() => {
        console.log("useEffect for memPhotoURLS, the state has been changed...")
        changeProfilePhoto();
    }, [profilePhoto]);

    useEffect(()=>{
        changeBackgroundPhoto()
    },[backgroundPhoto])


    async function changeProfilePhoto(){
        if (profilePhoto != null) {
            const profileRef = ref(
                storage,
                "inside/obituary/" + timeLineCode.trim() + "profile.jpg"
            );
            const snapshot = await uploadBytes(profileRef, profilePhoto);
            let Profile = await getDownloadURL(profileRef);

            let DocData = {
                Birthday: FriendTimeline.Birthday,
                Editors: FriendTimeline.Editors,
                Name: FriendTimeline.Name,
                NumofMems: FriendTimeline.NumofMems,
                Owner: FriendTimeline.Owner,
                Bio: FriendTimeline.Bio,
                Profile: Profile,
                Background: FriendTimeline.Background,
            };
            const ObituaryRef = doc(firestore, "obituaries/".concat(timeLineCode));
            const res = await updateDoc(ObituaryRef, DocData);
            window.location.reload();
        }
    }

    async function changeBackgroundPhoto(){
        if (backgroundPhoto != null) {

            const backgroundRef = ref(
                storage,
                "inside/obituary/" + timeLineCode.trim() + "background.jpg"
            );
            const snapshot = await uploadBytes(backgroundRef, backgroundPhoto);
            let Background = await getDownloadURL(backgroundRef);

            let DocData = {
                Birthday: FriendTimeline.Birthday,
                Editors: FriendTimeline.Editors,
                Name: FriendTimeline.Name,
                NumofMems: FriendTimeline.NumofMems,
                Owner: FriendTimeline.Owner,
                Bio: FriendTimeline.Bio,
                Profile: FriendTimeline.Profile,
                Background: Background,
            };
            const ObituaryRef = doc(firestore, "obituaries/".concat(timeLineCode));
            const res = await updateDoc(ObituaryRef, DocData);
            window.location.reload();
        }
    }

    function showingAddMemHelper() {
        if (currentUser == null) { return }
        if (FriendTimeline.Editors.includes(currentUser.uid)) {
            setShowAddMem(true);
        }

        if (FriendTimeline.Owner.trim() == currentUser.uid) {
            setShowEditProfileIcon(true)
            setShowAddMem(true);
            console.log("Why is this running?")
        }
    }

    console.log(ShowEditProfileIcon+" this better be false, showeditprofileicon...")

    const textChangeHandler = (event) => {
        event.preventDefault();
        setTimeLineCode(event.target.value);

    };

    async function pullTimelineWithParamsHandler(code) {
        //This gets the single obituary document
        let ObituaryAuthor;
        const docRef = doc(firestore, "obituaries/" + code.trim());

        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                setFriendTimeline(docSnap.data());
                ObituaryAuthor = docSnap.data().Owner;
            }
        });
        ;
        setTimeLineCode(code.trim());
        const querySnapshot = await getDocs(
            collection(firestore, "/obituaries/" + code.trim() + "/memories")
        );

        const tempMems = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let memoryData = {
                memory: doc.data(),
                docId: doc.id,
            };
            tempMems.push(memoryData);
        });
        setMemories(mappedMemsHelper(tempMems, ObituaryAuthor));
        setHideSearch(true);
    }


    async function pullTimelineHandler() {
        setMemPhotoURLs("/")
        let ObituaryAuthor;
        //This gets the single obituary document
        const docRef = doc(firestore, "obituaries/" + timeLineCode);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                setFriendTimeline(docSnap.data());
                ObituaryAuthor = docSnap.data().Owner;;
            }
        });

        const querySnapshot = await getDocs(
            collection(firestore, "/obituaries/" + timeLineCode + "/memories")
        );
        const tempMems = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let memoryData = {
                memory: doc.data(),
                docId: doc.id,
            };

            tempMems.push(memoryData);
        });
        setMemories(mappedMemsHelper(tempMems, ObituaryAuthor));

        setHideSearch(true);
    }

    //takes the memories pulled for timeline from firebase and displays them on the screen
    function mappedMemsHelper(memories, author) {
        let temp = [];
        let group = [];
        let year = "";

        for (let i = 0; i < memories.length; i++) {
            if (year != memories[i].docId.substring(0, 4)) {
                if (group.length > 0) {
                    temp.push(<MemoryWrapper> <Center> {group} </Center> </MemoryWrapper>);
                }
                group = [];
                year = memories[i].docId.substring(0, 4);
                temp.push(<Heading align="center">{year}</Heading>);
            }
            //prevents from having more than three mems in single row
            if (group.length > 2) {
                temp.push(<MemoryWrapper> <Center> {group} </Center></MemoryWrapper>);

                group = [];
            }

            group.push(

                <Memory

                    memory={memories[i]}
                    author={author}
                    timeLineCode={timeLineCode}
                    showModal={() => {
                        setShowDeleteModal(true);
                    }}
                >

                </Memory>

            );
        }
        if (group.length > 0) {
            temp.push(<MemoryWrapper><Center> {group} </Center></MemoryWrapper>);
        }

        setTesting(temp);

     
    }

    const MemoryBodyChangeHandler = (event) => {
        event.preventDefault();
        setMemoryBody(event.target.value);
    };



    async function FileChangeHandler(event) {
        event.preventDefault();
        //come back later to add functionality for uploading multiple photos
        if (event.target.files[0]) {
            setMemoryPhotos(event.target.files[0]);
        }

        if (event.target.files[0]) {
            //memoryPhotos do exist,

            const fileRef = ref(
                storage,
                "Obituaries/" +
                currentUser.uid +
                "/" +
                FriendTimeline.NumofMems +
                "MemPhoto.jpg"
            );

            setLoading(true);
            const snapshot = await uploadBytes(fileRef, event.target.files[0]);
            const tempPhotoURL = await getDownloadURL(fileRef);
            //curMemPhotoURL = tempPhotoURL;

            setMemPhotoURLs(tempPhotoURL);


        }


    };

    async function updateObituary() {
        setShowEditModal(false);

        let Birthday = FriendTimeline.Birthday;
        if (birthRef.current != null) {
            if (birthRef.current.value != "") {
                Birthday = birthRef.current.value;
            }
        }

        let Bio = FriendTimeline.Bio;
        if (bioRef.current != null) {
            if (bioRef.current.value != "") {
                Bio = bioRef.current.value;
            }
        }

        const ObituaryRef = doc(firestore, "obituaries/".concat(timeLineCode));

      
        let name = FriendTimeline.Name;
        if (nameRef.current != null) {
            if (nameRef.current.value != "") {
                name = nameRef.current.value;
            }
        }

        if (FriendTimeline.Death != "") {
            let Death = FriendTimeline.Death;
            if (deathRef.current != null) {
                if (deathRef.current.value != "") {
                    Death = deathRef.current.value;
                }
            }
            const res = await updateDoc(ObituaryRef, { Death: Death });
        }

        

        let DocData = {
            Birthday: Birthday,
            Name: name,
            Bio: Bio,
        };

        const res = await updateDoc(ObituaryRef, DocData);
        window.location.reload();
    }

    async function UploadMemoryHandler() {
        //Create new Memory Object

        let newDate = formYear + "-" + formMonth + "-" + formDay

        let DOCDATA = {
            AuthorName: currentUser.displayName,
            AuthorID: currentUser.uid,
            Body: memoryBody,
            Date: newDate,
            PicExists: false,
        };


        if (memPhotoURLs != null) {
            DOCDATA.PicExists = memPhotoURLs;
        }


        const ObituaryRef = doc(firestore, "obituaries/".concat(timeLineCode));
        try {
            await updateDoc(ObituaryRef, {
                NumofMems: FriendTimeline.NumofMems + 1,
            });
            //then need to update FriendTimeline within state so Friend Timeline has NumofMems that is one higher

            const NewMemoryRef = doc(
                firestore,
                "obituaries/"
                    .concat(timeLineCode)
                    .concat(
                        "/memories/" + newDate + FriendTimeline.NumofMems + 1
                    )
            );
            await setDoc(NewMemoryRef, DOCDATA);
        } catch (error) {
            console.log(error + " this is my error");
        }


        //.then create updated memory document by
        //adding the new downloadLink to PicExists AND
        //update obituary document by
        //increasing NumofMems by 1
        //.then update Memory docu
        //Call useEffect or whatever function pulls down all the memories to have new memory displayed as well
        setShowModal(false);
        setLoading(false);
        pullTimelineHandler();
    }

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        setBackgroundPhoto(fileObj);
    };

    const handleMemPhotoClick = () => {
        // 👇️ open file input box on click of other element
        memPhotoRef.current.click();
    };

    const handleFileChangeProfile = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        setProfilePhoto(fileObj);
    };

    const handleClickBackground = () => {
        // 👇️ open file input box on click of other element
        backgroundRef.current.click();
    };

    function letEdit() {
        if (currentUser == null) { return; }
        if (FriendTimeline.Owner.trim() == currentUser.uid.trim()) {
            return true;
        }
        FriendTimeline.Editors.forEach((editor) => {
            if (editor.trim() == currentUser.uid.trim()) {
                return true;
            }
        });
        return false;
    }

    const handleClickProfile = () => {
        // 👇️ open file input box on click of other element
        profilePhotoRef.current.click();
    };

    return (


        <>
            {colorMode === "light" ? (
                <div
                    style={{
                        backgroundColor: "white",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#1A202C",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            )}
            <div
                style={{
                    position: "relative",
                    bottom: "10em",
                }}
            >
                {showEditModal && (
                    <Modal
                        closeModal={() => {
                            setShowEditModal(false);
                        }}
                    >
                        <Form.Group id="name">
                            <Form.Label >
                               Name:
                            </Form.Label>
                            <Form.Control
                                type="string"
                                ref={nameRef}
                                required
                                style={{
                                    borderStyle: "solid",
                                    borderColor: "#000000",
                                }}
                            />
                        </Form.Group>
                        <Form.Group id="birthDate">
                            <Form.Label>Birth Date: </Form.Label>
                            <Form.Control
                                type="date"
                                ref={birthRef}
                            />
                        </Form.Group>
                        {FriendTimeline.Death && (
                            <Form.Group id="deathDate">
                                <Form.Label>Date of Passing: </Form.Label>
                                <Form.Control type="date" ref={deathRef} />
                            </Form.Group>
                        )}
                        <Form.Group id="bio">
                            <Form.Label>Enter the bio: </Form.Label>
                            <Form.Control
                                type="string"
                                ref={bioRef}
                                required
                                style={{
                                    borderColor: "#4d647f",
                                    padding: "1em",
                                }}
                            />
                        </Form.Group>

{/*                         
                        <Button
                            size="lg"
                            bg={"blue.400"}
                            color={"white"}
                            justify={"center"}
                            _hover={{
                                bg: "blue.500",
                            }}
                            onClick={handleClickBackground}
                        >
                            Upload Background Photo
                        </Button>

                       
                        <Button
                            size="lg"
                            bg={"blue.400"}
                            color={"white"}
                            justify={"center"}
                            _hover={{
                                bg: "blue.500",
                            }}
                            onClick={handleClickProfile}
                        >
                            Upload Profile Photo
                        </Button> */}

                        <Button position="absolute" right="3%" bottom="5%" backgroundColor="blue.400" onClick={updateObituary}>Submit Changes</Button>
                        {/* <Button
                            onClick={() => {
                                setShowEditModal(false);
                            }}
                        >
                            Return to Timeline
                        </Button> */}
                    </Modal>
                )}
                <Flex justify={"center"} p="6">
                    {!hideSearch && (
                        <VStack>
                            <Heading as="h3" size="lg">
                                <Text textAlign={"center"}>
                                    Enter the Timeline code below, and find your
                                    friend Today!
                                </Text>
                            </Heading>
                            {/* <Text textAlign={'center'} fontSize='lg'>Enter the Timeline code below, and find your friend Today!</Text> */}
                            <HStack p={"6"}>
                                <Input
                                    onSubmit={pullTimelineHandler}
                                    onChange={textChangeHandler}
                                    type="text"
                                    placeholder={"Enter Code: "}
                                    size={"md"}
                                    width={"500px"}
                                ></Input>
                                <Button onClick={pullTimelineHandler}>
                                    Submit
                                </Button>
                            </HStack>
                        </VStack>
                    )}
                </Flex>
                {FriendTimeline != null && (
                    <Center py={6}>
                        <Box
                            maxW={"90%"}
                            w={"full"}
                            // bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={"2xl"}
                            rounded={"md"}
                            overflow={"hidden"}
                        >
                            <Image
                                h={"120px"}
                                w={"full"}
                                src={FriendTimeline.Background}
                                objectFit={"cover"}
                            />
                        
                            <Flex justify={"center"} mt={-12}>
                                            
                            { ShowEditProfileIcon && (     
                                            <Button
                                                onClick={()=>{ handleClickBackground()}}
                                                backgroundColor="black"
                                                cursor="pointer"
                                                position="relative"
                                                left="52%"
                                                top="-60px"
                                                
                                            >
                                               <EditIcon
                                           color="white" w={6} h={6}/> 
                                            </Button>  
        )}

                        <input
                            style={{ display: "none" }}
                            ref={backgroundRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                         <input
                            style={{ display: "none" }}
                            ref={profilePhotoRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileChangeProfile}
                        />
                                <Avatar
                                    cursor="pointer"
                                    // _hover={()=>{}}
                                    size={"xl"}
                                    src={FriendTimeline.Profile}
                                    alt={"Author"}
                                    css={{
                                        border: "2px solid white",
                                    }}
                                />
                                         { ShowEditProfileIcon && (
            <Button
            size="xs"
            onClick={() => {
                handleClickProfile();
            }}
            backgroundColor="black"
            cursor="pointer"
            position="relative"
            left="-40px"
            top="15px"
            
        >
           <EditIcon color="white" w={4} h={4}/> 
        </Button>  
                                            // <EditIcon
                                            //     onClick={() => {
                                            //         setShowEditModal(true);
                                            //     }}
                                            //     cursor="pointer"
                                            //     position="relative"
                                            //     left="-40px"
                                            //     top="15px"
                                            //     w={6}
                                            //     h={6}
                                            // />
                                        )}
                            </Flex>

                            <Box p={6}>
                                <Stack spacing={0} align={"center"} mb={5}>
                                    <center>
                                    <Heading
                                        fontSize={"6xl"}
                                        fontWeight={500}
                                        fontFamily={"body"}
                                    >
                                        {/*Name:*/} {FriendTimeline.Name}</Heading>
                                        <Heading
                                        fontSize={"2xl"}
                                        fontWeight={500}
                                        fontFamily={"body"}>
                                        {/*Born:*/} {monthMap.get(FriendTimeline.Birthday.substring(5,7)) +" "+FriendTimeline.Birthday.substring(8,)+ ", "+FriendTimeline.Birthday.substring(0,4)}

                                        <br></br>
                                        to
                                        {FriendTimeline.Death != "" && (
                                            <p> {/*Died:*/} {monthMap.get(FriendTimeline.Death.substring(5,7)) +" "+FriendTimeline.Death.substring(8,)+ ", "+FriendTimeline.Death.substring(0,4)}</p>
                                        )}
                                        <br></br>
                                    </Heading>
                                    </center>
                                    <Heading fontSize={"md"} align={"center"}>
                                        {" "}
                                        {FriendTimeline.Bio}
                                    </Heading>
                                    
                                    {!showModal && showAddMem && (
                                        <Button
                                            onClick={() => {
                                                setShowModal(true);
                                            }}
                                            style={{
                                                margin: "5px",
                                            }}
                                        >
                                            Upload A Memory
                                        </Button>
                                    )}
                                    {currentUser != null && FriendTimeline.Owner.trim() ==
                                        currentUser.uid.trim() && (
                                            <Button
                                                onClick={() => {
                                                    setShowEditModal(true);
                                                }}
                                                leftIcon={<EditIcon />}
                                                style={{
                                                    margin: "5px",
                                                }}
                                            >
                                                Edit Info
                                            </Button>
                                        )}
                                </Stack>                         
                            </Box>
                        </Box>
                    </Center>
                )}
                
                {testing}
                {memories}
                
                    <div>
                        {showModal && (
                            <Modal
                                closeModal={() => {
                                    setShowModal(false);
                                }}
                            >

                                    <form onSubmit={UploadMemoryHandler}>
                                        <Heading size="2xl">
                                            Upload A Memory
                                        </Heading>
                                        <Input
                                            marginTop="10px"
                                            required="true"
                                            onChange={MemoryBodyChangeHandler}
                                            placeholder="Enter memory body here..."
                                        ></Input>
                                        <HStack marginBottom="5px" marginTop="5px">
                                        <Select show={10} width={1/3} onChange={(e)=>{setFormDay(e.target.value); }}  placeholder="Day">
                                            {mappedDays}
                                        </Select>
                                        <Select width={1/3} onChange={(e)=>{setFormMonth(e.target.value); }} placeholder="Month">
                                            {mappedMonths}
                                        </Select>
                                        <Select width={1/3} onChange={(e)=>{setFormYear(e.target.value);}} placeholder="Year">
                                           {mappedYears}
                                        </Select>

                                        </HStack >
                                        <Button
                                            style={{
                                                padding: "0px 10px",
                                                margin: "5px",
                                            }}
                                            onClick={handleMemPhotoClick}
                                            for="fileInput"
                                        >
                                            Upload Photo
                                        </Button>
                                        <Avatar
                                        
                                        marginLeft="5px" src={memPhotoURLs}></Avatar>
                                        <Input
                                            style={{ display: "none" }}
                                            multiple
                                            onChange={FileChangeHandler}
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            ref={memPhotoRef}
                                        ></Input>

<Button colorScheme="blue" position="absolute" right="10px" bottom="10px" onClick={UploadMemoryHandler}>
                                            Add Memory
                                </Button>
                                    </form>
                                
                                
                            </Modal>
                        )}
                    </div>
                    <Text textAlign={'center'} p={2} m={2}>© 2022 Memento Memories. All rights reserved. Created using Chakra Templates. </Text>

            </div>
        </>
    )
}

/*
<h2>Born {FriendTimeline.Dates[0]}</h2>
   <h2>Died {FriendTimeline.Dates[1]}</h2>
*/
export default FindTimeline;
