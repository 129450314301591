import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import {
    Button,
    FormControl,
    Heading,
    Input,
    FormLabel,
} from "@chakra-ui/react";
import Modal from "../UI/Modal";
import { useState, useEffect } from "react";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    getDocs,
    collection,
    updateDoc,
    deleteDoc,
} from "firebase/firestore";

function MappedTimeline(props) {
    const { setUserObituary, currentUser } = useAuth();
    const [ShowEditorModal, setShowEditorModal] = useState(false);
    //I can use the TimeLineID to pull down any documents from storage upon loading the MappedTimeLine
    const [showModal, setShowModal] = useState(false);
    const [friendCode, setFriendCode] = useState("");
    const [ObituaryName, setObituaryName] = useState(null);
    const [isObi, setIsObi] = useState(false);
    const firestore = getFirestore();

    let navigate = useNavigate();

    useEffect(() => {
        if (ObituaryName == null) fetchName();
    }, []);

    async function fetchName() {
        let ObiName = "";
        const obiRef = doc(firestore, "obituaries/" + props.TimelineID.trim());
        const obiSnap = await getDoc(obiRef);

        ObiName = obiSnap.data().Name;
        let isAlive = obiSnap.data().Death;
        if (isAlive.length > 1) {
            setIsObi(true);
        }
        setObituaryName(ObiName);
    }

    function friendCodeChangeHandler(event) {
        event.preventDefault();
        setFriendCode(event.target.value);
    }

    async function addEditor() {
        //add ObiId to users list of Editor_Obi_IDS
        //go to obituary and add to list of Editors in her obituary
        //need friends UserId -- Is stored in     friendCode
        let currentEditTimelines = [];
        const docRef = doc(firestore, "users/" + friendCode);
        const docSnap = await getDoc(docRef);
        let EditorTimelines = docSnap.data().Editor_Obi_IDS;

        EditorTimelines.push(props.TimelineID);
        const res = await updateDoc(docRef, {
            Editor_Obi_IDS: EditorTimelines,
        });

  

        let ObiEditors = [];
        const obiRef = doc(firestore, "obituaries/" + props.TimelineID.trim());

        const obiSnap = await getDoc(obiRef);
        ObiEditors = obiSnap.data().Editors;
        ObiEditors.push(friendCode);
        const obiRes = await updateDoc(obiRef, { Editors: ObiEditors });
        setShowEditorModal(false)
        alert("Editor succesfully Added!")
    
    }

    function openTimeline() {
        setUserObituary(props.TimelineID);
        navigate("/timeline/" + props.TimelineID);
    }

    //A Tag must be edited for production to final Domain
    return (
        <>
            {ShowEditorModal && (
                <Modal
                    closeModal={() => {
                        setShowEditorModal(false);
                    }}
                >
                    <FormControl onSubmit={addEditor}>
                        <FormLabel>Enter Friend's Code</FormLabel>
                        <Input
                            onChange={friendCodeChangeHandler}
                            type="text"
                            
                        ></Input>
                        <Button marginTop="10px" onClick={addEditor}>Add Editor</Button>
                        {/* <Button
                            m={2}
                            onClick={() => {
                                setShowEditorModal(false);
                            }}
                        >
                            Return to Profile
                        </Button> */}
                    </FormControl>
                </Modal>
            )}
            {showModal && (
                <Modal
                    
                    closeModal={() => {
                        setShowModal(false);
                    }}
                >
                    <div
                    
                    
                    >
                        <Heading fontSize={"4xl"}>
                            Copy Link to Share with your Friends!
                        </Heading>

                        <a
                            href={"FindTimeline/".concat(
                                props.TimelineID.trim()
                            )}
                            target="_blank"
                        >
                            mementomemories.com/timeline/
                            {props.TimelineID.trim()}
                        </a>
                        {/* THIS LINE NEEDS TO BE CHANGED WHEN PUBLISHED */}
                        <Button m={2}
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    "mementomemories.com/timeline/".concat(
                                        props.TimelineID.trim()
                                    )
                                );
                            }}
                        >
                            Copy to Clipboard
                        </Button>
                        {/* <Button
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Return to Profile
                        </Button> */}
                    </div>
                </Modal>
            )}
            <div style={{ outline: "1px solid black" }}>
                <h1>
                    <b>
                        {ObituaryName}
                        {!ObituaryName && "Unnamed"}'s{" "}
                    </b>
                    {isObi && "Obituary"}
                    {!isObi && "Timeline"}
                </h1>
                {/*<h1><b>This is the timeline ID: </b>{props.TimelineID}</h1> */}
                <Button
                    onClick={openTimeline}
                    style={{
                        height: "2em",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        backgroundColor: "#4299E1",
                        color: "white",
                        position: "relative",
                        margin: "4px",
                    }}
                >
                    Open Timeline{" "}
                </Button>
                <Button
                    onClick={() => {
                        setShowModal(true);
                    }}
                    style={{
                        height: "2em",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        backgroundColor: "grey",
                        color: "white",
                        position: "relative",
                        margin: "4px",
                    }}
                >
                    Share Timeline
                </Button>
                {props.hideEditor && (
                    <Button
                        onClick={() => {
                            setShowEditorModal(true);
                        }}
                        style={{
                            height: "2em",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                            backgroundColor: "grey",
                            color: "white",
                            position: "relative",
                            margin: "4px",
                        }}
                    >
                        {" "}
                        Add Editor{" "}
                    </Button>
                )}
            </div>
        </>
    );
}

export default MappedTimeline;
