import classes from "./Header.module.css";
import { useAuth, logout } from "../../store/AuthContext";
import { useState } from "react";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import image1 from "../../assets/memento memories-1 (5).png";
import image2 from "../../assets/memento memories-1 (2).png";
// import {
//     Box,
//     Flex,
//     Text,
//     IconButton,
//     Button,
//     Stack,
//     HStack,
//     Collapse,
//     Link,
//     useColorModeValue,
//     useBreakpointValue,
//     useDisclosure,
//     useColorMode,
//     color,
//     Image,
// } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useMediaQuery } from "@chakra-ui/media-query";

import React from "react";

import {
    chakra,
    Image,
    Box,
    Flex,
    useColorModeValue,
    VisuallyHidden,
    HStack,
    Button,
    useDisclosure,
    VStack,
    IconButton,
    useColorMode,
    CloseButton,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { Logo } from "@choc-ui/logo";
const Header = (props) => {
    let navigate = useNavigate();
    const { login, logout, currentUser, setLoggingIn } = useAuth();
    const [error, setError] = useState("");
    const { colorMode, toggleColorMode } = useColorMode();
    var pressed = true;
    async function handleLogout() {
        setError("");

        if (localStorage.getItem('XFM143') != null){localStorage.removeItem('XFM143');}  
        if (localStorage.getItem('userProfile') != null){localStorage.removeItem('userProfile');}  

        try {
            await logout();
            setLoggingIn(true);
            navigate("/login");
        } catch {
            setError("Failed to log out");
        }
    }

    const loginClickHandler = () => {
        navigate("/login");
        console.log("I've been clicked");
    };

    //     return (
    //         // <Box>
    //         <Flex
    //             h={"5em"}
    //             bg={useColorModeValue("blue.400", "gray.800")}
    //             color={useColorModeValue("gray.600", "white")}
    //             // minH={'60px'}
    //             // py={{ base: 1 }}
    //             px={{ base: 4 }}
    //             borderBottom={1}
    //             borderStyle={"solid"}
    //             borderColor={useColorModeValue("gray.200", "gray.900")}
    //             align={"center"}
    //         >
    //             <Flex
    //                 flex={{ base: 1 }}
    //                 justify={{ base: "center", md: "start" }}
    //                 direction={isNotSmallerScreen ? "row" : "column"}
    //             >
    //                 {colorMode === "light" ? (
    //                     <Image
    //                         onClick={() => {
    //                             navigate("/");
    //                         }}
    //                         alt={"logo"}
    //                         src={image2}
    //                         style={{
    //                             cursor: "pointer",
    //                             height: "20em",
    //                             width: "20em",
    //                             padding: "0px",
    //                             margin: "0px",
    //                         }}
    //                     />
    //                 ) : (
    //                     <Image
    //                         onClick={() => {
    //                             navigate("/");
    //                         }}
    //                         alt={"logo"}
    //                         src={image1}
    //                         style={{
    //                             cursor: "pointer",
    //                             height: "20em",
    //                             width: "20em",
    //                             padding: "0px",
    //                             margin: "0px",
    //                         }}
    //                     />
    //                 )}
    //             </Flex>
    //             {/* <Flex direction={isNotSmallerScreen ? "row" : "column"}> */}
    //                 <HStack
    //                     flex={{ base: 1, md: 0 }}
    //                     justify={"flex-end"}
    //                     spacing={6}
    //                 >
    //                     {currentUser ? (
    //                         <>
    //                             <Button
    //                                 onClick={() => {
    //                                     navigate("/profile");
    //                                 }}
    //                                 bg={"black"}
    //                                 color={"white"}
    //                             >
    //                                 My Profile
    //                             </Button>
    //                             <Button
    // onClick={() => {
    //     navigate("/timeline");
    // }}
    // bg={"black"}
    // color={"white"}
    //                             >
    //                                 Find a Friend
    //                             </Button>
    //                             <Button
    //                                 onClick={() => {
    //                                     navigate("/create");
    //                                 }}
    //                                 bg={"black"}
    //                                 color={"white"}
    //                             >
    //                                 Create
    //                             </Button>
    //                             <Button
    //                                 onClick={handleLogout}
    //                                 bg={"black"}
    //                                 color={"white"}
    //                             >
    //                                 Log Out
    //                             </Button>
    //                         </>
    //                     ) : (
    //                         <Button
    //                             onClick={loginClickHandler}
    //                             color={"white"}
    //                             bg={"black"}
    //                         >
    //                             Log In
    //                         </Button>
    //                     )}
    //                     <Button onClick={toggleColorMode} bg={"black"}>
    //                         {colorMode === "light" ? (
    //                             <MoonIcon color={"white"} />
    //                         ) : (
    //                             <SunIcon />
    //                         )}
    //                     </Button>
    //                 </HStack>
    //             </Flex>
    //         // </Flex>
    //         // </Box>
    //     );
    // };

    // () => {
    const bg = useColorModeValue("blue.400", "gray.800");
    const mobileNav = useDisclosure();
    return (
        <div id="change">
            {/* // <React.Fragment> */}
            <Flex
                // h={"5em"}
                bg={useColorModeValue("blue.400", "gray.800")}
                color={useColorModeValue("gray.600", "white")}
                // padding = {4}
                maxH={"120px"}
                align={"center"}
            >
                <chakra.header
                    bg={bg}
                    w="full"
                    px={{
                        base: 2,
                        sm: 4,
                    }}
                    // py={1}
                    // marginTop={'-100px'}
                    // marginBottom={'-100px'}
                    shadow="md"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mx="auto"
                    >
                        <Flex>
                            <chakra.a
                                href="/"
                                title="Choc Home Page"
                                display="flex"
                                alignItems="center"
                            >
                                {colorMode === "light" ? (
                                    <Image
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                        alt={"logo"}
                                        src={image2}
                                        style={{
                                            cursor: "pointer",
                                            height: "20em",
                                            width: "20em",
                                            padding: "0px",
                                            margin: "0px",
                                        }}
                                    />
                                ) : (
                                    <Image
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                        alt={"logo"}
                                        src={image1}
                                        style={{
                                            cursor: "pointer",
                                            height: "20em",
                                            width: "20em",
                                            padding: "0px",
                                            margin: "0px",
                                        }}
                                    />
                                )}
                            </chakra.a>
                            {/* <Logo />
                            <VisuallyHidden>MementoMemories</VisuallyHidden>
                        <chakra.h1 fontSize="xl" fontWeight="medium" ml="2">
                            MementoMemories
                        </chakra.h1> */}
                        </Flex>
                        <HStack display="flex" alignItems="center" spacing={1}>
                            <HStack
                                // spacing={1}
                                // mr={1}
                                color="brand.500"
                                display={{
                                    base: "none",
                                    md: "inline-flex",
                                }}
                            >
                                {currentUser ? (
                                    <>
                                        <Button
                                            variant="ghost"
                                            onClick={() => {
                                                navigate("/profile");
                                            }}
                                            bg={"black"}
                                            color={"white"}
                                        >
                                            My Profile
                                        </Button>

                                        <Button
                                            variant="ghost"
                                            onClick={() => {
                                                navigate("/buypre");
                                            }}
                                            bg={"black"}
                                            color={"white"}
                                        >
                                            Buy
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            onClick={handleLogout}
                                            bg={"black"}
                                            color={"white"}
                                        >
                                            Log Out
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="ghost"
                                        onClick={loginClickHandler}
                                        color={"white"}
                                        bg={"black"}
                                    >
                                        Log in
                                    </Button>
                                )}

                                <Button onClick={toggleColorMode} bg={"black"}>
                                    {colorMode === "light" ? (
                                        <MoonIcon color={"white"} />
                                    ) : (
                                        <SunIcon />
                                    )}
                                </Button>
                            </HStack>
                            <Box
                                display={{
                                    base: "inline-flex",
                                    md: "none",
                                }}
                            >
                                <IconButton
                                    display={{
                                        base: "flex",
                                        md: "none",
                                    }}
                                    aria-label="Open menu"
                                    fontSize="20px"
                                    color="gray.800"
                                    _dark={{
                                        color: "inherit",
                                    }}
                                    variant="ghost"
                                    icon={<AiOutlineMenu />}
                                    onClick={mobileNav.onOpen}
                                />

                                <VStack
                                    style={{
                                        position: "absolute",
                                        zIndex: "10",
                                    }}
                                    pos="absolute"
                                    top={0}
                                    left={0}
                                    right={0}
                                    display={mobileNav.isOpen ? "flex" : "none"}
                                    flexDirection="column"
                                    p={2}
                                    pb={2}
                                    m={1}
                                    bg={bg}
                                    spacing={3}
                                    rounded="sm"
                                    shadow="sm"
                                >
                                    <CloseButton
                                        aria-label="Close menu"
                                        onClick={mobileNav.onClose}
                                    />

                                    {currentUser ? (
                                        <>
                                            <Button
                                                w="full"
                                                variant="ghost"
                                                onClick={() => {
                                                    navigate("/profile");
                                                }}
                                                bg={"black"}
                                                color={"white"}
                                            >
                                                My Profile
                                            </Button>

                                            <Button
                                                w="full"
                                                variant="ghost"
                                                onClick={() => {
                                                    navigate("/buypre");
                                                }}
                                                bg={"black"}
                                                color={"white"}
                                            >
                                                Buy
                                            </Button>
                                            <Button
                                                w="full"
                                                variant="ghost"
                                                onClick={handleLogout}
                                                bg={"black"}
                                                color={"white"}
                                            >
                                                Log Out
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            w="full"
                                            variant="ghost"
                                            onClick={loginClickHandler}
                                            color={"white"}
                                            bg={"black"}
                                        >
                                            Log in
                                        </Button>
                                    )}
                                    <Button
                                        w="full"
                                        onClick={toggleColorMode}
                                        bg={"black"}
                                    >
                                        {colorMode === "light" ? (
                                            <MoonIcon color={"white"} />
                                        ) : (
                                            <SunIcon />
                                        )}
                                    </Button>
                                </VStack>
                            </Box>
                        </HStack>
                    </Flex>
                </chakra.header>
            </Flex>

            {/* </React.Fragment> */}
        </div>
    );
};

export default Header;
