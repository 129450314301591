import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home"; //page users find when they come to site
import MyProfile from "./pages/MyProfile";
import UserLogin from "./pages/UserLogin"; //can be made to be modal as well
import { AuthProvider } from "./store/AuthContext";
import history from "./store/history";
import Header from "./components/UI/Header";
import Buy from "./pages/Buy";
import Testing from "./pages/Testing";
import QRouter from "./pages/QRouter";
import BuyPre from "./pages/BuyPre";
import About from "./pages/About";
import XFhilW956EC from "./pages/XFhilW956EC";
import { Elements } from "@stripe/react-stripe-js";
import {
    Flex,
    ChakraProvider,
    Container,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    Center,
    useColorModeValue,
    ButtonGroup,
    IconButton,
} from "@chakra-ui/react";
import FindTimeline from "./pages/FindTimeline";
//import {useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from  '@stripe/stripe-js';

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
    );
};



function App() {
    
    //const stripe = useStripe()
    const stripePromise = loadStripe(`${process.env.STRIPE_TEST_KEY}`)
    return (
        <AuthProvider>
            <ChakraProvider>
                <Elements stripe={stripePromise}>
                <Header />
                <div>
                    <Flex flexDirection="column" width="100wh" height="100vh">
                        <Routes>
                            <Route
                                path="/login"
                                element={<UserLogin />}
                            ></Route>
                            <Route
                                path="/timeline"
                                element={<FindTimeline />}
                            ></Route>
                            <Route
                                path="/timeline/:id"
                                element={<FindTimeline />}
                            ></Route>
                            <Route
                                path="/QRouter/:id"
                                element={<QRouter />}
                            ></Route>
                            <Route
                                path="/QRouter"
                                element={<QRouter />}
                            ></Route>
                            <Route
                                path="/XFhilW956EC"
                                element={<XFhilW956EC/>}
                            ></Route>
                            <Route
                                path="/profile"
                                element={<MyProfile />}
                            ></Route>
                            <Route path="/buy" element={<Buy/>}></Route>
                            <Route
                                path="/testing"
                                element={<Testing />}
                            ></Route>
                            <Route path="/about" element={<About />}></Route>
                            <Route path="/buypre" element={<BuyPre />}></Route>
                            <Route path="/*" element={<Home />}></Route>
                        </Routes>
                    </Flex>
                </div>
                </Elements>
            </ChakraProvider>
        </AuthProvider>
    );
}

export default App;
