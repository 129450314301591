import React, { useEffect, useState } from "react";
import { useAuth } from "../store/AuthContext";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import ProfilePhoto from "../components/UI/ProfilePhoto";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import MappedTimeline from "../components/Timelines/MappedTimeline";
import {
    Button,
    Flex,
    Stack,
    Heading,
    Box,
   
    FormControl,
    Input,
    InputGroup,
    FormLabel,
    Text,
    InputRightElement,
    useColorModeValue,
    Center,
    Spacer,
    VStack,
    Container,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import Modal from "../components/UI/Modal";
function MyProfile() {
    const { currentUser, userProfile, setUserProfile } = useAuth();
    const firestore = getFirestore();
    const [showModal, setShowModal] = useState(false);
    const [showFriendModal, setShowFriendModal] = useState(false)
    const [showEditorModal, setShowEditorModal] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('XFM143') != null){localStorage.removeItem('XFM143');}
        console.log("Is useEffect even running?")    

  
        // "17-6-2022"
        //if(userProfile==""){
        //    const Data = JSON.parse(window.localStorage.getItem("userProfile"));
        //    if(userProfile=="" && Data != null){
        //        setUserProfile(Data);
        //    }
        //}else if(userProfile!=""){
        //    window.localStorage.setItem(
        //        "userProfile",
        //         JSON.stringify(userProfile)
        //    )
        //}

     
        console.log(userProfile=="");

        
        //if(userProfile==null && Data !=null){
        //    window.location.reload();
        //}
        console.log(typeof(userProfile)+ " this is the type while not loading...")
    }, [userProfile]);
    console.log(" this is userProfile"+ userProfile)
    console.log(userProfile=="")
    console.log(currentUser, " this is currentUser");

   

    const mappedTimelines = [
        userProfile.Owned_Obi_IDS.map((timeLineID) => (
            <MappedTimeline TimelineID={timeLineID} hideEditor={true} />
        )),
    ];
    const mappedFriendTimelines = [
        userProfile.Editor_Obi_IDS.map((timeLineID) => (
            <MappedTimeline TimelineID={timeLineID} hideEditor={false} />
        )),
    ];

    console.log(typeof userProfile.Owned_Obi_IDS + " this is the type");

    return (
        <>
        {showFriendModal && 
                <Modal
                    closeModal={() => {
                        setShowFriendModal(false);
                        console.log("I best be closing");
                    }}
                ><Text> To add a friend's timeline. Copy your editor code and share it with a friend who has 
                        purchased a timeline. They can then add you as an editor on whatever timeline they want 
                        and you can access it right from your homepage. Otherwise have them share their QR Code 
                        or the URL!
                        </Text></Modal>}
            {showEditorModal && (
                <Modal
                    closeModal={() => {
                        setShowEditorModal(false);
                        console.log("I best be closing");
                    }}
                >
                    <Heading fontSize={"2x-1"}>
                        Copy this code and share with your friends to be added
                        as an editor to their timelines
                    </Heading>
                    <p>{currentUser.uid}</p>
                    <Button
                        onClick={() => {
                            navigator.clipboard.writeText(currentUser.uid);
                        }}
                    >
                        Copy to Clipboard
                    </Button>
                    {/* <Button
                        onClick={() => {
                            setShowEditorModal(false);
                        }}
                    >
                        Return to page
                    </Button> */}
                </Modal>
            )}
            <Flex
                minH={"100vh"}
                align={"center"}
                justify={"center"}
                bg={useColorModeValue("gray.50", "gray.800")}
            >
                <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                    <Stack align={"center"}>
                        <Heading fontSize={"4xl"} textAlign={"center"}>
                            Your Profile
                        </Heading>
                    </Stack>
                    <Box
                        rounded={"lg"}
                        bg={useColorModeValue("white", "gray.700")}
                        boxShadow={"lg"}
                        p={8}
                    >
                        <Stack spacing={4}>
                            <Flex justify={"center"}>
                                <Stack>
                                    <Box>
                                        <Text fontSize={"2xl"}>Name:</Text>
                                        <Text fontSize={"3xl"}>
                                            {" "}
                                            {currentUser.displayName}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text fontSize={"2xl"}>Email:</Text>
                                        <Text fontSize={"3xl"}>
                                            {" "}
                                            {currentUser.email}{" "}
                                        </Text>
                                    </Box>
                                </Stack>
                            </Flex>

                            <ProfilePhoto />
                            <Button
                                onClick={() => {
                                    setShowEditorModal(true);
                                }}
                            >
                                Get Shareable Editor Code
                            </Button>
                            <Stack spacing={10} pt={2}></Stack>

                            <Stack pt={6}></Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
            <Box
                bg={useColorModeValue("gray.50", "gray.900")}
                color={useColorModeValue("gray.700", "gray.200")}
            >
                <Container
                    as={Stack}
                    maxW={"6xl"}
                    py={6}
                    direction={{ base: "column", md: "row" }}
                    justify={{ base: "center", md: "space-between" }}
                    align={{ base: "center", md: "center" }}
                >
                    {/* <Text>© 2022 Chakra Templates. All rights reserved</Text> */}
                    <VStack textAlign={"center"}>
                        <Flex
                            w="full"
                            alignContent="center"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                maxW="sm"
                                borderWidth="1px"
                                rounded="lg"
                                p={3}
                                shadow="lg"
                            >
                                <Heading
                                    as="h1"
                                    size="lg"
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    Your Timelines
                                </Heading>
                                {userProfile.Owned_Obi_IDS.length == 0 && (
                                    <Heading as="h4" size="sm" py={2}>
                                        We're sorry, but you have not been added
                                        to any other timelines yet
                                    </Heading>
                                )}
                                {userProfile.Owned_Obi_IDS.length > 0 &&
                                    mappedTimelines}
                                <br></br>
                                <Button
                                    onClick={() => {
                                        console.log("I've been clicked");
                                        navigate("/buypre");
                                    }}
                                    bg={"blue.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "blue.500",
                                    }}
                                >
                                    Buy a Timeline
                                </Button>
                            </Box>
                        </Flex>
                    </VStack>

                    <VStack textAlign={"center"} p={3}>
                        <Flex
                            w="full"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                maxW="sm"
                                borderWidth="1px"
                                rounded="lg"
                                p={3}
                                shadow="lg"
                            >
                                <Heading as="h1" size="lg">
                                    Your Friends' Timelines
                                </Heading>
                                {userProfile.Editor_Obi_IDS.length == 0 && (
                                    <Heading as="h4" size="sm" py={2}>
                                        We're sorry, but you have not been added
                                        to any other timelines yet
                                    </Heading>
                                )}
                                {userProfile.Editor_Obi_IDS.length > 0 &&
                                    mappedFriendTimelines}
                                <Button
                                    my={2}
                                    bg={"blue.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "blue.500",
                                    }}
                                    onClick={() => {
                                        setShowFriendModal(true);
                                    }}
                                >
                                    Find a Friends' Timeline
                                </Button>
                            </Box>
                        </Flex>
                    </VStack>

                </Container>
                <Text textAlign={'center'} p={2} m={2}>© 2022 Memento Memories. All rights reserved. Created using Chakra Templates. </Text>
            </Box>
        </>
    );
}

export default MyProfile;


