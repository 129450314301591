import React from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import {
    getFirestore,
    doc,
    setDoc,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { useAuth } from '../store/AuthContext';
import { useEffect } from 'react';
import {
    Spinner
} from "@chakra-ui/react";


function XFhilW956EC() {

    const { currentUser, userProfile} = useAuth();
    let navigate = useNavigate();
    const firestore = getFirestore();

    useEffect(() => {
       finisher()
    }, []);

    async function finisher(){
        const Data = JSON.parse(window.localStorage.getItem("XFM143")) 
        if(Data==null){
            navigate('/profile')
        }
        if(Data.purchaseData==null || Data.purchaseRef==null || Data.newOwnedTimelines==null ||
            Data.obituaryData==null|| Data.obituaryRef==null){
            navigate('/profile') 
        }
         
        //Do Validation to make sure that stored data has the correct keys...

        const docRef = doc(
            firestore, Data.obituaryRef
        );
        console.log( Data.obituaryRef+" this is obi ref")
        const res = await setDoc(docRef, Data.obituaryData);
        //
        // Now I need to modify UserProfile as well to add the new Obi_ID to the doc
        
        // Add the Id to the user's document with owned Obi_ids
        const docRef2 = doc(firestore, "users/" + currentUser.uid);
        userProfile.Owned_Obi_IDS=Data.newOwnedTimelines;
        const res2 = await updateDoc(docRef2, {
            Owned_Obi_IDS: Data.newOwnedTimelines,
        });

        const docRef3 = doc(
            firestore, Data.purchaseRef
        );
        const res3 = await setDoc(docRef3, Data.purchaseData);
        navigate(
            "/timeline/" +
            currentUser.uid +
            String(userProfile.Owned_Obi_IDS.length - 1)
        );
        //
    }

/*
 window.localStorage.getItem("loggingIn") != "false"
 const Data = JSON.parse(window.localStorage.getItem("userProfile"))
*/

/*
async function createObi(e) {
        if (!nameRef.current.value) {
            alert("A Name must be entered to complete the purchase.");
            return;
        }

        if (
            !nameRef.current.value ||
            !birthRef.current.value ||
            !address1Ref.current.value ||
            !cityRef.current.value ||
            !stateRef.current.value ||
            !zipRef.current.value ||
            !emailRef.current.value ||
            !purchaseFirstNameRef.current.value ||
            !purchaseLastNameRef.current.value
        ) {
            alert("All inputs should be filled to complete the purchase.");
            return;
        }

        //    window.localStorage.setItem("userProfile",JSON.stringify(userProfile)) 
        //    window.localStorage.setItem("loggingIn", "false");
           

        //stripePaymentHandler().then(async function (paymentReturn) {
            //
            // If the payment succeeds, we create the document. Else we had an error...
            
            //console.log(typeof(!deathRef.current.value) + " " +typeof(birthRef.current.value))
            console.log(
                //paymentReturn +
                " this was returned by the promise in payment transacion"
            );

            //
            // Create the obituary
            
            let DeathString =""
            if(isAlive!=true){
                
                DeathString = deathRef.current.value;
            }

            
            try {
                const docRef = doc(
                    firestore,
                    "obituaries/" +
                    currentUser.uid +
                    userProfile.Owned_Obi_IDS.length
                );
                
                const res = await setDoc(docRef, {
                    Name: nameRef.current.value,
                    Birthday: birthRef.current.value,
                    Death: DeathString,
                    Bio: "Edit Your Bio...",
                    Owner: currentUser.uid,
                    Editors: [],
                    NumofMems: 0,
                    Profile: "",
                    Background: "",
                });
                //
                // Now I need to modify UserProfile as well to add the new Obi_ID to the doc
                if (userProfile.Owned_Obi_IDS.length == 0) {
                    const timeLineID = new String(currentUser.uid) + "0";
                    var OwnedTimelines = new Array(timeLineID);
                } else {
                    const timeLineID = new String(currentUser.uid).concat(
                        new String(userProfile.Owned_Obi_IDS.length)
                    );
                    console.log("String to be added " + timeLineID);
                    var OwnedTimelines = userProfile.Owned_Obi_IDS;
                    OwnedTimelines.push(timeLineID);
                    console.log("final array to be added " + OwnedTimelines);
                }
                //
                // Add the Id to the user's document with owned Obi_ids
                const docRef2 = doc(firestore, "users/" + currentUser.uid);
                userProfile.Owned_Obi_IDS=OwnedTimelines;
                const res2 = await updateDoc(docRef2, {
                    Owned_Obi_IDS: OwnedTimelines,
                });

                //
                // Deal with storing the purchase on firebase
                const docRef3 = doc(
                    firestore,
                    "Purchases/" +
                    currentUser.uid +
                    String(userProfile.Owned_Obi_IDS.length - 1)
                );
                const res3 = await setDoc(docRef3, {
                    UID: currentUser.uid,
                    OBIID:
                        currentUser.uid +
                        String(userProfile.Owned_Obi_IDS.length - 1),
                    AddressLine1: address1Ref.current.value,
                    ShipmentFirstname: purchaseFirstNameRef.current.value,
                    ShipmentLastname: purchaseLastNameRef.current.value,
                    ObiName: nameRef.current.value,
                    City: cityRef.current.value,
                    Email: emailRef.current.value,
                    State: stateRef.current.value,
                    ZipCode: zipRef.current.value,
                    "!SHIPPED": false,
                    "!QRoute": "null",
                });
                navigate(
                    "/timeline/" +
                    currentUser.uid +
                    String(userProfile.Owned_Obi_IDS.length - 1)
                );
            } catch (e) {
                //
                // Set error here...
                console.log(e);
            }
        //});
    }


*/


  return (
    <center><Spinner thickness='6px'
    speed='0.65s'
    emptyColor='gray.200'
    color='blue.500'
    size='xl'/></center>
  )
}

export default XFhilW956EC