import React, { useEffect, useRef } from "react";
import { useAuth } from "../../store/AuthContext";
import { useState } from "react";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase";
import { updateProfile } from "firebase/auth";
import classes from "./ProfilePhoto.module.css";
import { Avatar, Button, Box, Flex, Spinner } from "@chakra-ui/react";
import Modal from "./Modal";
function ProfilePhoto() {
    const [photoURL, setPhotoURL] = useState(
        "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
    );
    const [loading, setLoading] = useState(false);
    const [showfullImage, setShowFullImage] = useState(false);
    const { currentUser } = useAuth();
    const inputRef = useRef(null);

    
    useEffect(() => {
        console.log("running useEffect")
        if (currentUser.photoURL != null) {
            console.log("current user is not null")
            setPhotoURL(currentUser.photoURL);
        }
        console.log("this is photoURl "+ photoURL)
    }, [photoURL]);

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        upload(fileObj)
    };

    async function upload(file) {
        const fileRef = ref(
            storage,
            "inside/" + currentUser.uid + "/profile.jpg"
        );
        setLoading(true);
        const snapshot = await uploadBytes(fileRef, file);
        const tempPhotoURL = await getDownloadURL(fileRef);
        setPhotoURL(tempPhotoURL);
        console.log("This is new PhotoURL " + tempPhotoURL);
        const trash = await updateProfile(currentUser, { photoURL: tempPhotoURL });

        setLoading(false);
        alert("Uploaded file!");
        setLoading(false);
        window.location.reload();
    }



    return (
        <>

            {showfullImage &&  <Modal closeModal={()=>{setShowFullImage(false)}}><img src={photoURL}></img></Modal>}
           
            <Flex justify={"center"}>
                {loading &&  <center><Spinner thickness='6px'
    speed='0.65s'
    emptyColor='gray.200'
    color='blue.500'
    size='xl'/></center>}
                <Avatar onClick={()=>{setShowFullImage(true); console.log("I clicked the photo")}} src={photoURL} size="2xl" />
            </Flex>
            <Box>
                <Flex justify={"center"}>
                    <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                    />

                    <Button
                        size="lg"
                        bg={"blue.400"}
                        color={"white"}
                        justify={"center"}
                        _hover={{
                            bg: "blue.500",
                        }}
                        onClick={()=>{        inputRef.current.click();}}
                    >
                        Change Photo
                    </Button>
                </Flex>
            </Box>
           
        </>
    );
}

export default ProfilePhoto;

