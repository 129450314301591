import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../../store/AuthContext";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
    Center,
    useColorModeValue,
} from "@chakra-ui/react";

import { FcGoogle } from "react-icons/fc";

const Login = (props) => {
    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, currentUser, userProfile, signInWithGoogle, setUserProfile } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const firestore = getFirestore();

    useEffect(() => {
        console.log("I am running!")
        console.log(currentUser + " "+ userProfile)
        console.log(currentUser==null)
        console.log(currentUser)
        console.log(loading)
        if (currentUser && userProfile!="" ) {
            setLoading(false);
            console.log("navigating here in Login!!!")
            console.log(currentUser)
            console.log(userProfile)
            console.log(loading)
            navigate("/profile");
        }
    }, [currentUser, userProfile]);



    async function handleSubmit() {
        // e.preventDefault()

        console.log("logging in");
        try {
            setError("");
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value).then(
                (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    // Obtainint the Document from FireStore with User Profile Information
                    console.log(user, "this is user object");
                    const docRef = doc(firestore, "users/" + user.uid);
                    getDoc(docRef).then((docSnap) => {
                        if (docSnap.exists()) {
                            setUserProfile(docSnap.data());
                            console.log("Document data:", docSnap.data());
                            //navigate("/profile");
                        } else {
                            console.log(
                                "Document was not found on Login. Was document created on account signUp... Error!"
                            );
                        }
                    });
                }
            );
        } catch {
            setError("Failed to Login");
        }

        setLoading(false);
    }

    const googleLogin = () => {
        console.log("I've been clicked :)");
        signInWithGoogle();
        setLoading(true);
    };
    //console.log(JSON.stringify(user)+" :This is current User")
    //console.log(typeof signup+"--This is the type, and also should be function " + typeof handleSubmit)
    return (
        <div>

        
        <Flex
            minH={"100vh"}
            align={"center"}
            justify={"center"}
            bg={useColorModeValue("gray.50", "gray.800")}
        >
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Log in to your account!</Heading>
                </Stack>
                <Box
                    rounded={"lg"}
                    bg={useColorModeValue("white", "gray.700")}
                    boxShadow={"lg"}
                    p={8}
                >
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input ref={emailRef} type="email" />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input ref={passwordRef} type="password" />
                        </FormControl>
                        <Stack spacing={5}>
                            <Stack
                                direction={{ base: "column", sm: "row" }}
                                align={"start"}
                                justify={"space-between"}
                            >
                           
                            </Stack>
                            <Button
                                onClick={() => handleSubmit()}
                                //navigate={"/profile"}
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500",
                                }}
                            >
                                Log in
                            </Button>
                            <Button
                                onClick={() => props.loggingIn(false)}
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500",
                                }}
                            >
                                Sign up
                            </Button>
                            <Center p={0}>
                                <Button
                                    onClick={googleLogin}
                                    w={"full"}
                                    maxW={"md"}
                                    variant={"outline"}
                                    leftIcon={<FcGoogle />}
                                >
                                    <Center>
                                        <Text>Log in with Google</Text>
                                    </Center>
                                </Button>
                            </Center>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
        
        <Text textAlign={'center'} m={2} p={2}>© 2022 Memento Memories. All rights reserved. Created using Chakra Templates. </Text>
        </div>

        

    );

    //   return (
    //     <>
    //       <Card style={{borderColor:'#4d647f'}}>
    //         <Card.Body>
    //             <h1 className="text-center mb-4"> Prop Stack</h1>
    //           <h4 className="text-center mb-4">Log in</h4>
    //           {error && <Alert variant="danger">{error}</Alert>}
    //           <Form onSubmit={handleSubmit}>
    //             <Form.Group id="email">
    //               <Form.Label>Email</Form.Label>
    //               <Form.Control type="email" ref={emailRef} required style={{borderColor:'#4d647f'}}/>
    //             </Form.Group>
    //             <Form.Group id="password">
    //               <Form.Label>Password</Form.Label>
    //               <Form.Control type="password" ref={passwordRef} required style={{borderColor:'#4d647f'}}/>
    //             </Form.Group>
    //             <Button disabled={loading} className={classes.paddedButton} style={{backgroundColor:'#4d647f', borderColor:'#4d647f'}} type="submit">
    //               Log In
    //             </Button>
    //           </Form>
    //         </Card.Body>
    //       </Card>
    //       <div  className="w-100 text-center mt-2">
    //         Don't have an account? Sign Up
    //       </div>
    //       <Button onClick={ () => props.loggingIn(false)}>
    //         Sign Up
    //       </Button>
    //       <Button onClick={googleLogin}> Log In With Google</Button>
    //     </>
    //   )
};

export default Login;
