import React from "react";
import ReactDom from "react-dom";
import { Button, useColorMode } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { CloseIcon } from "@chakra-ui/icons";


const MODAL_STYLES_LIGHT = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "50px",
    zIndex: 1000,
};
const MODAL_STYLES_DARK = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#282c34",
    padding: "50px",
    zIndex: 1000,
};

const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, .7)",
    zIndex: 1000,
};

function Modal(props) {
    const { closeModal, children } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    let ref = useRef(null);

    const handleClickOutside = (event) => {
        //console.log(ref.current + " this is ref");
        if (ref.current && !ref.current.contains(event.target)) {

            closeModal();
            //console.log("Closing Modal");
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ref]);

    if (!children) {
        return null;
    }

    return ReactDom.createPortal(
        <>
            {colorMode === "light" ? (
                <div style={OVERLAY_STYLES}>
                    <div ref={ref} style={MODAL_STYLES_LIGHT}>
                        <CloseIcon position="absolute" right="25px" top="25px" cursor="pointer" onClick={()=>{closeModal()}}></CloseIcon>
                        <div >{children}</div>
                    </div>
                </div>
            ) : (
                <div style={OVERLAY_STYLES}>
                    <div ref={ref} style={MODAL_STYLES_DARK}>
                        <div >{children}</div>
                    </div>
                </div>
            )}
        </>,
        document.getElementById("portal")
    );
}

export default Modal;
