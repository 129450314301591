import {
    Box,
    VStack,
    Button,
    Flex,
    Divider,
    chakra,
    Grid,
    GridItem,
    Container,
    Text,
    useColorMode
} from "@chakra-ui/react";
import { } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";

interface FeatureProps {
    heading: string;
    text: string;
}
const Feature = ({ heading, text }: FeatureProps) => {
    return (
        <GridItem>
            <chakra.h3 fontSize="xl" fontWeight="600">
                {heading}
            </chakra.h3>
            <chakra.p>{text}</chakra.p>
        </GridItem>
    );
};

function About() {
    const { colorMode, toggleColorMode } = useColorMode();
    let navigate = useNavigate();
    return (
        <>

            {colorMode === "light" ? (
                <div
                    style={{
                        backgroundColor: "white",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#1A202C",
                        height: "3em",
                        padding: "3.4em",
                    }}
                >
                    &nbsp;
                </div>
            )}
            <Box
                style={{ position: "relative", bottom: "8em" }}
                as={Container}
                maxW="7xl"
                mt={14}
                p={4}

            >
                <Grid
                    templateColumns={{
                        base: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(2, 1fr)",
                    }}
                    gap={4}
                >
                    <GridItem colSpan={1}>
                        <VStack alignItems="flex-start" spacing="20px">
                            <chakra.h2 fontSize="3xl" fontWeight="700">
                                How does Memento Memories work?
                            </chakra.h2>
                            <Button
                                onClick={(e) => {
                                    {
                                        navigate("/login/");
                                    }
                                }}
                                colorScheme="green"
                                size="md"
                            >
                                Buy a Timeline Today!
                            </Button>
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <Flex>
                            <chakra.p>
                                Here at LivingMemories, we believe in giving the
                                user a great experience. We believe this is done
                                by allowing the user as much freedom as
                                possible. Learn some of our features below!
                            </chakra.p>
                        </Flex>
                    </GridItem>
                </Grid>
                <Divider mt={12} mb={12} />
                <Grid
                    m={3}
                    templateColumns={{

                        base: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                    }}
                    gap={{ base: "8", sm: "12", md: "16" }}
                >
                    <Feature
                        heading={"Affordable Prices"}
                        text={
                            "In the create tab, you can buy a lifetime membership for just 99$!"
                        }
                    />
                    <Feature
                        heading={"Personalization"}
                        text={
                            "We allow personlization of both of those who are creating memories and being remembered."
                        }
                    />
                    <Feature
                        heading={"Light/Dark Mode"}
                        text={
                            "We have enabled a light/dark mode and other user-friendly nuances to make your experience better!"
                        }
                    />
                </Grid>

            </Box>
            <Text textAlign={'center'} p={2} m={2}>© 2022 Memento Memories. All rights reserved. Created using Chakra Templates. </Text>

        </>
    );
}

export default About;
