import React, { useEffect } from "react";
import Header from "../components/UI/Header";
import Login from "../components/authentication/Login";
import { useState } from "react";
import Signup from "../components/authentication/Signup";
import { useAuth } from "../store/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
function UserLogin() {
    const [loginState, SetLoginState] = useState(true);
    const { currentUser } = useAuth();
    const LoginSignupToggle = (props) => {
        SetLoginState(props);
    };
    let navigate = useNavigate();

    useEffect(() => {
        if (currentUser != null) {
            navigate("/profile");
        }
    }, []);

    return (
        <>
            {loginState && <Login loggingIn={LoginSignupToggle} />}
            {!loginState && <Signup loggingIn={LoginSignupToggle} />}
        </>
    );
}

export default UserLogin;
